import BigNumber from "bignumber.js";
import { RootState } from "../../../../store";
import { selectUserDepositedVaults } from "../../../vaults/redux/selectors";
import { BIGNUMBER_ZERO } from "../../../../utils/bignumber";
import { compoundInterest } from "../../../../utils/numbers";
import { yearlyToDaily } from "../../../../utils/apyConversions";
import { selectPriceForVault } from "../../../prices/redux/selectors";
import countDepositedBalance from "../../../vaults/views/Vault/utils/countDepositedBalance";
import countTotalValue from "../../../../utils/countTotalValue";

const INITIAL_STATS = {
  deposited: 0,
  daily: 0,
  monthly: 0,
  yearly: 0,
  apy: 0,
  depositedVaults: 0,
};

export const selectWalletGlobalStats = (state: RootState) => {
  const walletAddress = state.wallet.address
  if (!walletAddress) {
    return INITIAL_STATS;
  }

  const userVaults = selectUserDepositedVaults(state);
  if (userVaults.length === 0) {
    return INITIAL_STATS;
  }

  const newGlobalStats = {
    ...INITIAL_STATS,
    depositedVaults: userVaults.map(vault => vault.id).length
  };

  for (const vault of userVaults) {
    const balances = state.vaults.balancesMap[vault.id];
    if (!balances) {
      continue;
    }

    const tokenBalance = new BigNumber(balances.stakedBalance ?? 0);
    if (tokenBalance.lte(BIGNUMBER_ZERO)) {
      continue;
    }

    const oraclePrice = selectPriceForVault(state, vault.id, vault.oracle.type);
    const vaultUsdBalance = countTotalValue(
      countDepositedBalance(balances, vault.token.decimals, vault.earn.decimals),
      vault.token.decimals,
      oraclePrice.toNumber()
    );

    // Add vault balance to total
    newGlobalStats.deposited += vaultUsdBalance;

    if (vaultUsdBalance <= 0) {
      continue;
    }

    // Collect yield for each vault
    const totalYearly = state.vaults.apysMap[vault.id];
    if (!totalYearly) {
      continue;
    }

    const totalDaily = yearlyToDaily(totalYearly);
    newGlobalStats.daily += vaultUsdBalance * totalDaily;
    newGlobalStats.monthly += vaultUsdBalance + compoundInterest(totalDaily, vaultUsdBalance, 1, 30);
    newGlobalStats.yearly += vaultUsdBalance * totalYearly;
  }

  // Skip yield calc if user has no deposits
  if (newGlobalStats.deposited <= 0) {
    return newGlobalStats;
  }

  // Compute average apy
  newGlobalStats.apy = newGlobalStats.yearly / newGlobalStats.deposited;

  return newGlobalStats;
};