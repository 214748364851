import BigNumber from "bignumber.js";
import Vault from "../../../../services/vaults/Vault";
import { RootState } from "../../../../store";
import { PnL } from "../../../../utils/pnl";
import { BIGNUMBER_ONE } from "../../../../utils/bignumber";
import { selectPriceForVault } from "../../../prices/redux/selectors";
import { isNumeric } from "../../../../utils/numbers";
import { selectTimelineByVaultId } from "./selectTimelineByVaultId";

export type VaultPnl = {
  hasTransactions: boolean;
  totalYield: BigNumber;
  totalYieldUsd: BigNumber;
  totalPnlUsd: BigNumber
  deposit: BigNumber;
  depositUsd: BigNumber;
  usdBalanceAtDeposit: BigNumber;
  balanceAtDeposit: BigNumber;
  yieldPercentage: BigNumber;
  pnlPercentage: BigNumber;
  tokenDecimals: number;
  oraclePrice: BigNumber;
  oraclePriceAtDeposit: BigNumber;
}

export const selectVaultPnl = (
  state: RootState,
  vaultId: Vault['id'],
  walletAddress?: string
): VaultPnl | null => {
  walletAddress = walletAddress ?? state.wallet.address;
  if (!walletAddress) {
    return null;
  }

  const vault = state.vaults.vaults.find(vault => vault.id === vaultId);
  if (!vault) {
    return null;
  }

  const sortedTimeline = selectTimelineByVaultId(state, vault.id, walletAddress);
  const depositToken = vault.token;
  const ppfs = new BigNumber(state.vaults.balancesMap[vault.id]?.pricePerFullShare).shiftedBy(-vault.token.decimals) ?? BIGNUMBER_ONE

  const pnl = new PnL();
  for (const row of sortedTimeline) {
    if (isNumeric(row.shareDiff) && isNumeric(row.shareToUnderlyingPrice) && isNumeric(row.underlyingToUsdPrice)) {
      pnl.addTransaction({
        shares: new BigNumber(row.shareDiff),
        price: new BigNumber(row.underlyingToUsdPrice),
        ppfs: new BigNumber(row.shareToUnderlyingPrice),
      });
    }
  }

  const oraclePrice = selectPriceForVault(state, vault.id, vault.oracle.type);
  const oraclePriceAtDeposit = pnl.getRemainingSharesAvgEntryPrice();
  const balanceAtDeposit = pnl.getRemainingShares().times(pnl.getRemainingSharesAvgEntryPpfs());
  const usdBalanceAtDeposit = balanceAtDeposit.times(oraclePriceAtDeposit);

  const depositNow = pnl.getRemainingShares().times(ppfs);
  const depositUsd = depositNow.times(oraclePrice);
  const totalYield = depositNow.minus(balanceAtDeposit);
  const totalYieldUsd = totalYield.times(oraclePrice);
  const unrealizedPnl = pnl.getUnrealizedPnl(oraclePrice, ppfs);
  const yieldPercentage = totalYield.dividedBy(balanceAtDeposit);
  const totalPnlUsd = unrealizedPnl.usd;
  const pnlPercentage = totalPnlUsd.dividedBy(usdBalanceAtDeposit);

  return {
    hasTransactions: pnl.getHasTransactions(),
    totalYield,
    totalYieldUsd,
    totalPnlUsd,
    deposit: depositNow,
    depositUsd,
    usdBalanceAtDeposit,
    balanceAtDeposit,
    yieldPercentage,
    pnlPercentage,
    tokenDecimals: depositToken.decimals,
    oraclePrice,
    oraclePriceAtDeposit,
  };
};
