import { createSlice } from "@reduxjs/toolkit";
import groupBy from "lodash/groupBy";
import type { Draft } from "immer";
import { fetchWalletTimeline } from "./actions/fetchWallletTimeline";
import { DatabarnPriceType, PriceSerieItem, Timebucket, TimelineItem } from "../../../services/databarn/IDatabarnService";
import { sortBy } from "lodash";
import { fetchShareToUnderlying } from "./actions/fetchShareToUnderlying";
import { fetchUnderlyingToUsd } from "./actions/fetchUnderlyingToUsd";

export type VaultTimelineEntity = TimelineItem & {
  transactionId: string;
}

type StatusType = 'idle' | 'pending' | 'fulfilled' | 'rejected';

export interface BucketData {
  status: StatusType;
  data: PriceSerieItem[];
}

export type DashboardState = {
  byAddress: {
    [address: string]: {
      timeline: {
        byVaultId: {
          [vaultId: string]: VaultTimelineEntity[];
        }
      },

      shareToUnderlying: {
        byVaultId: {
          [vaultId: string]: {
            byTimebucket: {
              [K in Timebucket]?: BucketData;
            };
          };
        };
      };
      underlyingToUsd: {
        byVaultId: {
          [vaultId: string]: {
            byTimebucket: {
              [K in Timebucket]?: BucketData;
            };
          };
        };
      };
    }
  },
}

type AddressStatePart = 'underlyingToUsd' | 'shareToUnderlying';

const initialState: DashboardState = {
  byAddress: {}
}

const dashboardSlice = createSlice({
  name: '´dashboard',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchWalletTimeline.fulfilled, (sliceState, action) => {
      const vaultTxs = action.payload.timeline.filter(entity => !entity.productKey.startsWith('beefy:boost'));
      const walletState = getOrCreateAnalyticsAddressState(sliceState, action.payload.walletAddress.toLocaleLowerCase());
      const sortedVaultTxs = sortBy(vaultTxs, entity => new Date(entity.datetime).getTime());
      walletState.timeline.byVaultId = groupBy(sortedVaultTxs, tx => tx.displayName);
    });

    builder.addCase(fetchShareToUnderlying.fulfilled, (sliceState, action) => {
      const { data, vaultId, walletAddress, timebucket } = action.payload;
      const bucketState = setStatus(
        sliceState,
        'shareToUnderlying',
        vaultId,
        timebucket,
        walletAddress,
        'fulfilled'
      );
      bucketState.data = data;
    });

    builder.addCase(fetchShareToUnderlying.pending, (sliceState, action) => {
      const { timebucket, walletAddress, vaultId } = action.meta.arg;
      setStatus(sliceState, 'shareToUnderlying', vaultId, timebucket, walletAddress, 'pending');
    });

    builder.addCase(fetchShareToUnderlying.rejected, (sliceState, action) => {
      const { timebucket, walletAddress, vaultId } = action.meta.arg;
      setStatus(sliceState, 'shareToUnderlying', vaultId, timebucket, walletAddress, 'rejected');
    });

    builder.addCase(fetchUnderlyingToUsd.fulfilled, (sliceState, action) => {
      const { data, vaultId, walletAddress, timebucket } = action.payload;
      const bucketState = setStatus(
        sliceState,
        'underlyingToUsd',
        vaultId,
        timebucket,
        walletAddress,
        'fulfilled'
      );
      bucketState.data = data;
    });

    builder.addCase(fetchUnderlyingToUsd.pending, (sliceState, action) => {
      const { timebucket, walletAddress, vaultId } = action.meta.arg;
      setStatus(sliceState, 'underlyingToUsd', vaultId, timebucket, walletAddress, 'pending');
    });

    builder.addCase(fetchUnderlyingToUsd.rejected, (sliceState, action) => {
      const { timebucket, walletAddress, vaultId } = action.meta.arg;
      setStatus(sliceState, 'underlyingToUsd', vaultId, timebucket, walletAddress, 'rejected');
    });
  }
});

function setStatus(
  sliceState: Draft<DashboardState>,
  part: AddressStatePart,
  vaultId: string,
  timebucket: Timebucket,
  walletAddress: string,
  status: StatusType
) {
  const bucketState = getOrCreateAnalyticsAddressPartVaultTimeBucketState(
    sliceState,
    walletAddress,
    part,
    vaultId,
    timebucket
  );
  bucketState.status = status;
  return bucketState;
}

function getOrCreateAnalyticsAddressPartVaultTimeBucketState(
  sliceState: Draft<DashboardState>,
  walletAddress: string,
  part: AddressStatePart,
  vaultId: string,
  timebucket: Timebucket
) {
  const partState = getOrCreateAnalyticsAddressPartVaultState(
    sliceState,
    walletAddress,
    part,
    vaultId
  );
  let bucketState = partState.byTimebucket[timebucket];

  if (!bucketState) {
    bucketState = partState.byTimebucket[timebucket] = {
      data: [],
      status: 'idle',
    };
  }

  return bucketState;
}

function getOrCreateAnalyticsAddressPartVaultState(
  sliceState: Draft<DashboardState>,
  walletAddress: string,
  part: AddressStatePart,
  vaultId: string,
) {
  const partState = getOrCreateAnalyticsAddressPartState(sliceState, walletAddress, part);
  let vaultState = partState.byVaultId[vaultId];

  if (!vaultState) {
    vaultState = partState.byVaultId[vaultId] = { byTimebucket: {} };
  }

  return vaultState;
}

function getOrCreateAnalyticsAddressPartState(
  sliceState: Draft<DashboardState>,
  walletAddress: string,
  part: AddressStatePart,
) {
  const addressState = getOrCreateAnalyticsAddressState(sliceState, walletAddress);
  let partState = addressState[part];

  if (!partState) {
    partState = addressState[part] = { byVaultId: {} };
  }

  return partState;
}

function getOrCreateAnalyticsAddressState(
  sliceState: Draft<DashboardState>,
  walletAddress: string
) {
  walletAddress = walletAddress.toLowerCase();
  let addressState = sliceState.byAddress[walletAddress];

  if (!addressState) {
    addressState = sliceState.byAddress[walletAddress] = {
      timeline: { byVaultId: {} },
      shareToUnderlying: { byVaultId: {} },
      underlyingToUsd: { byVaultId: {} }
    };
  }

  return addressState;
}



export default dashboardSlice.reducer;