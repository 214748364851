import { Contract } from 'ethers';
import { AppDispatch, GetAppState } from '../../../store';
import getSignerOrProvider from '../../wallet/getSignerOrProvider';
import abi from '../abis/vault';
import {
  withdrew,
  withdrawing,
  withdrawFailed,
} from './vaultsSlice';
import {
  NotificationType,
  notificationAdded,
} from '../../notifications/redux/notificationsSlice';
import { resolveFriendlyErrorMessage } from '../../notifications/redux/utils';
import fetchBalances from './fetchBalances';

const withdrawAll = (vaultId: string) => {
  return async (dispatch: AppDispatch, getState: GetAppState) => {
    const { vaults, wallet } = getState();
    if (!wallet.connected) {
      return;
    }

    const vault = vaults.vaults.find((v) => v.id === vaultId);
    if (!vault) {
      return;
    }

    dispatch(withdrawing(vaultId));
    try {
      const contract = new Contract(
        vault.earn.tokenAddress,
        abi,
        getSignerOrProvider(vault.networkId, wallet)
      );
      const tx = await contract.withdrawAll();
      await tx.wait();

      dispatch(fetchBalances([vaultId]));
      dispatch(withdrew(vaultId));
    } catch (e: any) {
      console.error(`Calling withdrawAll for vault ${vaultId}`, e);
      dispatch(withdrawFailed({ id: vaultId }));
      dispatch(
        notificationAdded({
          title: 'Error on withdraw',
          message: resolveFriendlyErrorMessage(e?.message || ''),
          type: NotificationType.error,
        })
      );
    }
  };
};

export default withdrawAll;
