import { useEffect, useState } from "react";
import Vault, { VaultStrategy } from "../../../../../../services/vaults/Vault";
import { PriceObject } from "../../../../../prices/redux/pricesSlice";
import { BalancesMap } from "../../../../redux/vaultsSlice";
import Select, { SelectOption } from "./Select";
import SortDirection from "./sorters/direction";
import sortByApy from "./sorters/sortByApy";
import sortByCreationDate from "./sorters/sortByCreationDate";
import sortByTvl from "./sorters/sortByTvl";
import { uniqBy } from "lodash";
import VaultIcon from "../VaultIcon";
import PlatformIcon from "../../../../../../components/PlatformIcon";
import { FiFilter, FiDollarSign } from "react-icons/fi";
import { BsSortDown, BsSortUp } from 'react-icons/bs';
import { GiBodyBalance, GiToken } from 'react-icons/gi';
import { useSearchParams } from "react-router-dom";
import NetworkFilters from "./NetworkFilters";
import getNetworks from "../../../../../../networks/getNetworks";
import Network from "../../../../../../networks/Network";

export type ListToolingProps = {
  className?: string;
  vaults: Vault[];
  balancesMap: BalancesMap;
  apysMap: { [vaultId: string]: number };
  lpPrices: PriceObject;
  tokenPrices: PriceObject;
  onVaultsFiltered: (filteredVaultIds: string[]) => void;
  onVaultSorted: (sortedVaultIds: string[]) => void;
}

type SortOption = {
  value: string;
  label: string;
  highLabel: string;
  lowLabel: string;
}


const SORT_OPTIONS: SortOption[] = [{
  value: 'creationdate',
  label: 'Creation date',
  highLabel: 'New',
  lowLabel: 'Old'
}, {
  value: 'apy',
  label: 'APY',
  highLabel: 'High',
  lowLabel: 'Low'
}, {
  value: 'tvl',
  label: 'TVL',
  highLabel: 'High',
  lowLabel: 'Low'
}];

const createOptions = (): SelectOption[] => {
  return SORT_OPTIONS.flatMap(opt => {
    return [{
      value: `${opt.value}-desc`,
      label: `${opt.label} (${opt.highLabel}-to-${opt.lowLabel})`,
      icon: <BsSortDown size={26} />
    }, {
      value: `${opt.value}-asc`,
      label: `${opt.label} (${opt.lowLabel}-to-${opt.highLabel})`,
      icon: <BsSortUp size={26} />
    }]
  });
}

const getSortDirection = (value: string): SortDirection => value.endsWith('asc') ? 'asc' : 'desc';

const ALL_VALUE = 'all';

const isAll = (value: string): boolean => value === ALL_VALUE;

const SORT_BY_OPTIONS = createOptions();

const ALL_TOKENS_OPTION = { label: 'All tokens', value: ALL_VALUE, icon: <FiFilter size={26} /> };
const ALL_HOSTS_OPTION = { label: 'All platforms', value: ALL_VALUE, icon: <FiFilter size={26} /> };

const VAULT_TYPE_OPTIONS: SelectOption[] = [{
  label: 'All vault types',
  value: ALL_VALUE,
  icon: <FiFilter size={26} />
}, {
  label: 'Single Assets',
  value: 'singles',
  icon: <GiToken size={26} />,
  backgroundColor: 'rgba(55, 171, 242, .35)'
}, {
  label: 'LP Tokens',
  value: 'lps',
  icon: <GiBodyBalance size={26} />,
  backgroundColor: 'rgba(55, 171, 242, .35)'
}, {
  label: 'Stablecoins',
  value: 'stables',
  icon: <FiDollarSign size={26} />,
  backgroundColor: 'rgba(55, 171, 242, .35)'
}];

const vaultTypeMatch = (vaultTypeStr: string, strategy: VaultStrategy): boolean => {
  return (vaultTypeStr === 'singles' && strategy === VaultStrategy.SingleStaking) ||
    (vaultTypeStr === 'stables' && strategy === VaultStrategy.StableStaking) ||
    (vaultTypeStr === 'lps' && strategy === VaultStrategy.LPStaking);
}

const SEARCH_PARAM_NETWORKS = 'chains';
const SEARCH_PARAM_PLATFORM = 'platform';
const SEARCH_PARAM_ASSET = 'asset';
const SEARCH_PARAM_VAULT_TYPE = 'vaultType';
const SEARCH_PARAM_SORT = 'sort';

const ListTooling: React.FC<ListToolingProps> = ({
  className,
  vaults,
  apysMap,
  balancesMap,
  lpPrices,
  tokenPrices,
  onVaultSorted,
  onVaultsFiltered
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedFarmHost, setSelectedFarmHost] = useState<string>(searchParams.get(SEARCH_PARAM_PLATFORM) || ALL_HOSTS_OPTION.value);
  const [selectedToken, setSelectedToken] = useState<string>(searchParams.get(SEARCH_PARAM_ASSET) || ALL_TOKENS_OPTION.value);
  const [selectedVaultType, setSelectedVaultType] = useState<string>(searchParams.get(SEARCH_PARAM_VAULT_TYPE) || VAULT_TYPE_OPTIONS[0].value);
  const [sortedBy, setSortedBy] = useState<string>(searchParams.get(SEARCH_PARAM_SORT) || SORT_BY_OPTIONS[0].value);
  const [selectedNetworks, setSelectedNetworks] = useState<number[]>(searchParams.get(SEARCH_PARAM_NETWORKS)?.split(',').map(Number).filter(Boolean) || []);
  const networks = getNetworks();

  useEffect(() => {
    const allTokensSelected = isAll(selectedToken);
    const allHostsSelected = isAll(selectedFarmHost);
    const allVaultTypesSelected = isAll(selectedVaultType);
    const allNetworksSelected = selectedNetworks.length === 0;

    if (allTokensSelected && allHostsSelected && allVaultTypesSelected && allNetworksSelected) {
      onVaultsFiltered(vaults.map(vault => vault.id));
      return;
    }

    let filteredVaults: Vault[] = [...vaults];

    if (!allNetworksSelected) {
      filteredVaults = filteredVaults.filter(vault => selectedNetworks.includes(vault.networkId));
    }
    if (!allVaultTypesSelected) {
      filteredVaults = filteredVaults.filter(vault => vaultTypeMatch(selectedVaultType, vault.strategy));
    }
    if (!allHostsSelected) {
      filteredVaults = filteredVaults.filter(vault => selectedFarmHost === vault.host.name);
    }
    if (!allTokensSelected) {
      filteredVaults = filteredVaults.filter(vault => vault.assets.some(asset => selectedToken === asset.name));
    }

    onVaultsFiltered(filteredVaults.map(vault => vault.id));
  }, [selectedToken, selectedFarmHost, selectedVaultType, selectedNetworks, vaults]);

  useEffect(() => {
    let sortedVaults: Vault[] = [];
    const sortDirection = getSortDirection(sortedBy);
    if (sortedBy.startsWith(SORT_OPTIONS[0].value)) {
      sortedVaults = sortByCreationDate(vaults, sortDirection);
    } else if (sortedBy.startsWith(SORT_OPTIONS[1].value)) {
      sortedVaults = sortByApy(vaults, apysMap, sortDirection);
    } else if (sortedBy.startsWith(SORT_OPTIONS[2].value)) {
      sortedVaults = sortByTvl(vaults, balancesMap, lpPrices, tokenPrices, sortDirection);
    }

    onVaultSorted(sortedVaults.map(v => v.id));
  }, [sortedBy, vaults]);

  const renderFarmHostsSelector = () => {
    const hosts: SelectOption[] = uniqBy(vaults.map(vault => ({
      label: vault.host.name,
      value: vault.host.name,
      icon: (<PlatformIcon platform={vault.host.id} size={23} borderWidth={2} />),
      backgroundColor: 'rgba(55, 171, 242, .35)'
    })), (vault) => vault.value);
    hosts.unshift(ALL_HOSTS_OPTION);

    return (
      <div>
        <Select
          key='hosts'
          options={hosts}
          onChange={item => {
            const value = item[0].value;
            setSelectedFarmHost(value);
            if (isAll(value)) {
              searchParams.delete(SEARCH_PARAM_PLATFORM);
            } else {
              searchParams.set(SEARCH_PARAM_PLATFORM, value);
            }
            setSearchParams(searchParams);
          }}
          value={hosts.find(host => host.value === selectedFarmHost) || ALL_HOSTS_OPTION}
        />
      </div>
    );
  }

  const renderTokenSelector = () => {
    const assets = uniqBy(vaults.map(vault => vault.assets).flat(), (asset => asset.id));
    const assetOptions: SelectOption[] = assets.map(asset => ({
      label: asset.name,
      value: asset.name,
      icon: (<VaultIcon asset={asset.id} size={23} borderWidth={2} />),
      backgroundColor: 'rgba(55, 171, 242, .35)'
    }));
    assetOptions.unshift(ALL_TOKENS_OPTION);

    return (
      <div>
        <Select
          key='tokens'
          options={assetOptions}
          onChange={item => {
            const value = item[0].value;
            setSelectedToken(value);
            if (isAll(value)) {
              searchParams.delete(SEARCH_PARAM_ASSET);
            } else {
              searchParams.set(SEARCH_PARAM_ASSET, value);
            }
            setSearchParams(searchParams);
          }}
          value={assetOptions.find(asset => asset.value === selectedToken) || ALL_TOKENS_OPTION}
        />
      </div>
    );
  };

  const renderVaultTypeSelector = () => (
    <div>
      <Select
        key='vaultType'
        options={VAULT_TYPE_OPTIONS}
        onChange={item => {
          const value = item[0].value;
          setSelectedVaultType(value);
          if (isAll(value)) {
            searchParams.delete(SEARCH_PARAM_VAULT_TYPE);
          } else {
            searchParams.set(SEARCH_PARAM_VAULT_TYPE, value);
          }
          setSearchParams(searchParams);
        }}
        value={VAULT_TYPE_OPTIONS.find(vaultType => vaultType.value === selectedVaultType) || VAULT_TYPE_OPTIONS[0]}
      />
    </div>
  )

  const renderSortSelector = () => (
    <div>
      <Select
        key='sort'
        options={SORT_BY_OPTIONS}
        onChange={item => {
          const value = item[0].value;
          setSortedBy(value);
          if (value === SORT_BY_OPTIONS[0].value) {
            searchParams.delete(SEARCH_PARAM_SORT);
          } else {
            searchParams.set(SEARCH_PARAM_SORT, item[0].value);
          }
          setSearchParams(searchParams);
        }}
        value={SORT_BY_OPTIONS.find(sortType => sortType.value === sortedBy) || SORT_BY_OPTIONS[0]}
      />
    </div>
  );

  const handleNetworkClick = (network: Network) => {
    setSelectedNetworks((prev) => {
      let newSelectedNetworks: number[];;

      const prevNonSelected = prev.length === 0;
      if (prevNonSelected) {
        newSelectedNetworks = [network.id];
      } else {
        newSelectedNetworks = prev.includes(network.id)
          ? selectedNetworks.filter(networkId => networkId !== network.id)
          : [...selectedNetworks, network.id];

        if (newSelectedNetworks.length === networks.length) {
          newSelectedNetworks = [];
        }
      }

      if (newSelectedNetworks.length > 0) {
        searchParams.set(SEARCH_PARAM_NETWORKS, newSelectedNetworks.join(','));
      } else {
        searchParams.delete(SEARCH_PARAM_NETWORKS);
      }
      setSearchParams(searchParams);

      return newSelectedNetworks;
    })
  }

  return (
    <div>
      <div>
        <NetworkFilters networks={networks} selectedNetworkIds={selectedNetworks} onNetworkClick={handleNetworkClick} className="mb-4" />
      </div>
      <div className="lg:flex space-between grid grid-cols-1 md:grid-cols-2" >
        <div className="md:flex gap-2 flex-row md:flex-col lg:flex-row md:items-start lg:grow grid grid-cols-1 md:grid-cols-1">
          {renderVaultTypeSelector()}
          {renderTokenSelector()}
          {renderFarmHostsSelector()}
        </div>
        <div className="mt-2 md:mt-0 md:flex md:justify-end">
          {renderSortSelector()}
        </div>
      </div>
    </div>
  );

}

export default ListTooling;