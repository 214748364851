import ReactSlider from 'react-slider';
import styled from 'styled-components';

export type SliderProps = {
    className?: string;
    onChange: (value: number) => void;
    value: number;
}

const Thumb = styled.div`
    border-radius: 50%;
    background-color: #fff;
    width: 25px;
    height: 25px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #000;
    font-size: 12px;
`;

const Track = styled.div`
    background-color: #fff;
    height: 5px;
`;

const Container = styled.div`

`;

const Labels = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Label = styled.div`
    font-size: 12px;
    color: #000;
    width: 30px;
`;

const StyledSlider = styled(ReactSlider)`
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
`;

const Slider: React.FC<SliderProps> = ({
    className,
    onChange,
    value
}) => {
    return (
        <Container className={className}>
            <StyledSlider
                renderThumb={(props: any, state: any) => <Thumb {...props}>{state.value}</Thumb>}
                renderTrack={(props: any) => <Track {...props} />}
                onChange={onChange as any}
                min={0}
                max={100}
                marks={[0, 25, 50, 75, 100]}
                step={1}
                value={value}
            />
            <Labels>
                <Label className='text-left'>0%</Label>
                <Label className='text-center'>25%</Label>
                <Label className='text-center'>50%</Label>
                <Label className='text-center'>75%</Label>
                <Label className='text-right'>100%</Label>
            </Labels>
        </Container>
    )
}

export default Slider;