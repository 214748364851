export enum PlatformIdentifier {
    SpookySwap,
    SpiritSwap,
    MagicSea
}

export type Platform = {
    id: PlatformIdentifier;
    icon: string;
    name: string;
    url: string;
    description: string;
}
