import { useCallback } from "react";
import { shallowEqual, useSelector } from "react-redux";
import useAppDispatch from "../../../hooks/useAppDispatch";
import { RootState } from "../../../store";
import { WalletState } from "../redux/walletSlice";
import connectWallet from "../redux/connectWallet";
import { Web3Modal } from "../createWeb3Modal";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";

export type UseWalletResult = {
    connectWallet: (web3Modal: Web3Modal) => void;
    web3ModalConnected: boolean;
} & Omit<WalletState, 'provider'>;

const useWallet = (): UseWalletResult => {
    const dispatch = useAppDispatch();
    const { address, chainId, isConnected: web3ModalConnected } = useWeb3ModalAccount();
    const { signer, connecting, disconnecting, connected } = useSelector(
        (state: RootState) => ({
            signer: state.wallet.signer,
            connected: state.wallet.connected,
            connecting: state.wallet.connecting,
            disconnecting: state.wallet.disconnecting
        }),
        shallowEqual
    );
    const boundConnectWallet = useCallback(
        (web3Modal: Web3Modal) => dispatch(connectWallet(web3Modal)),
        [dispatch]
    );
    return {
        signer,
        address: address || '',
        networkId: chainId as number,
        connected,
        connecting,
        disconnecting,
        web3ModalConnected,
        connectWallet: boundConnectWallet,
    };
};

export default useWallet;