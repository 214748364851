import Vault from "../../../../../../../services/vaults/Vault";
import SortDirection, { flipIfAsc } from "./direction";

const sortByCreationDate = (vaults: Vault[], sortDirection: SortDirection) => {
    const sortedVaults: Vault[] = [...vaults];
    return sortedVaults.sort(
        (v1, v2) => flipIfAsc(
            new Date(v2.creationDate).valueOf() - new Date(v1.creationDate).valueOf(),
            sortDirection
        )
    );
}

export default sortByCreationDate;