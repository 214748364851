import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import styled from 'styled-components';
import Vault, { VaultOracle } from '../../../../../../services/vaults/Vault';
import { RootState } from '../../../../../../store';
import useBalance from '../../../../hooks/useBalance';
import VaultIcon, { VaultIconProps } from '../VaultIcon';
import toFriendlyBigUsd from '../../../../../../utils/toFriendlyBigUsd';
import countTotalValue from '../../../../../../utils/countTotalValue';
import countDepositedBalance from '../../../Vault/utils/countDepositedBalance';
import toFriendlyBalance from '../../../Vault/utils/toFriendlyBalance';
import { BigNumber } from 'ethers';
import ContentBlock from '../../../../../../components/ContentBlock';
import { toFriendlyApy } from '../../../../../../utils/toFriendlyApy';
import NetworkIcon from '../../../../../../components/NetworkIcon';
import getNetwork from '../../../../../../networks/getNetwork';

const ICON_SIZE = 46;

export type VaultItemProps = {
  className?: string;
  vault: Vault;
};

const Container = styled.div``;

const Box = styled.button`
  text-align: left;
  width: 100%;
  position: relative;
  background-color: #73d2de;
  border-radius: 0.5rem;
  padding: 1rem;
  border: 0.2rem solid #80e4f1;
  opacity: 0.9;
  transition: background-color 0.15s ease-in-out;

  &:hover {
    background-color: #67c0cb;
  }
`;

const Icons = styled.div`
  position: absolute;
  left: 0.5rem;
  top: -${ICON_SIZE / 2}px;
`;

const StyledNetworkIcon = styled(NetworkIcon)`
  position: absolute;
  right: 0.5rem;
  top: -20px;
`;

const Name = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  color: #fff;
  margin-bottom: 1.5rem;
`;

const Content = styled.div`
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;

  margin: 0 auto;
  z-index: 1;
  top: -4px;
  position: relative;
  text-align: left;
`;

type StyledVaultIconProps = VaultIconProps & {
  zIndex: number;
  marginLeft: number;
};

const StyledVaultIcon = styled(VaultIcon) <StyledVaultIconProps>`
  position: relative;
  margin-left: ${(props) => props.marginLeft}px;
  z-index: ${(props) => props.zIndex};
`;

const PlatformName = styled.div`
  font-size: 0.85rem;
  letter-spacing: 0.1rem;
`;

const VaultItem: React.FC<VaultItemProps> = ({ vault }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { apysMap, initialized: vaultsInitialized } = useSelector(
    (state: RootState) => state.vaults
  );
  const { pricesInitialized, pricesTokens, pricesLps } = useSelector(
    ({ prices }: RootState) => ({
      pricesInitialized: prices.initialized,
      pricesTokens: prices.tokens,
      pricesLps: prices.lps,
    })
  );
  const { connected: isWalletConnected } = useSelector(
    (state: RootState) => state.wallet
  );
  const { balances } = useBalance(vault.id);
  const network = getNetwork(vault.networkId);

  const renderIcons = () => {
    const { assets } = vault;

    if (assets.length === 0) {
      return null;
    }

    return (
      <Icons>
        {assets.map((asset, index) => (
          <StyledVaultIcon
            asset={asset.id}
            key={index}
            size={ICON_SIZE}
            marginLeft={index === 0 ? 0 : -10}
            zIndex={vault.assets.length - index}
          />
        ))}
      </Icons>
    );
  };

  const handleOpenClick = () => {
    navigate(`/vaults/${vault.id}`, { state: { from: location } });
  };

  const renderContent = () => {
    const { oracle } = vault;
    const price =
      oracle.type === VaultOracle.Lp
        ? pricesLps[oracle.id]
        : pricesTokens[oracle.id];

    const renderMyWallet = () => {
      const deposited = toFriendlyBigUsd(
        countTotalValue(
          countDepositedBalance(
            balances,
            vault.token.decimals,
            vault.earn.decimals
          ),
          vault.token.decimals,
          price
        )
      );
      const available = toFriendlyBalance(
        BigNumber.from(balances.walletBalance || 0),
        vault.token.decimals
      );
      const isDepositedLoading = !pricesInitialized || !balances.initialized;

      return (
        <>
          <div className="text-center col-span-full">My wallet</div>
          <ContentBlock
            label="Deposited"
            value={deposited}
            isLoading={isDepositedLoading}
          />
          <ContentBlock
            label="Available"
            value={available}
            isLoading={!balances.initialized}
          />
        </>
      );
    };

    const isTvlLoading = !pricesInitialized || !vaultsInitialized;
    const tvl = toFriendlyBigUsd(
      countTotalValue(balances.totalBalance, vault.token.decimals, price)
    );
    const apyValue = apysMap[vault.id];
    const apy = toFriendlyApy(apyValue);

    return (
      <Content className="grid grid-cols-2 gap-4">
        <ContentBlock label="TVL" value={tvl} isLoading={isTvlLoading} />
        <ContentBlock label="APY" value={apy} isLoading={!vaultsInitialized} />
        {isWalletConnected && renderMyWallet()}
      </Content>
    );
  };

  return (
    <Container>
      <Box onClick={handleOpenClick}>
        {renderIcons()}
        <div className="flex justify-end">
          <PlatformName>{vault.host.name}</PlatformName>
        </div>
        <StyledNetworkIcon size={32} network={network} />
        <Name>{vault.name}</Name>
        {renderContent()}
      </Box>
    </Container>
  );
};

export default VaultItem;
