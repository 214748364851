import classNames from "classnames";
import { ComponentPropsWithRef } from "react";
import styled from "styled-components";
import Button from "../../../../../../components/Button";
import Loading from "../../../../../../components/Loading";

export type LoadingButtonProps = ComponentPropsWithRef<'button'> & {
    loading: boolean;
}

const StyledLoading = styled(Loading)` 
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const LoadingButton: React.FC<LoadingButtonProps> = ({
    className,
    children,
    loading,
    disabled,
    ...rest
}) => {

    const classes = classNames('relative', className);

    return (
        <Button {...rest} className={classes} disabled={loading || disabled}>
            {loading ? <>&nbsp;<StyledLoading color='#2c476e' lineWidth={4} width={40} /></> : children}
        </Button>
    )
}

export default LoadingButton;
