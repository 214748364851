import styled from 'styled-components';
// Assuming you're using SVGs or images
import TwitterImage from '../resources/assets/twitter.png';
import DiscordImage from '../resources/assets/discord.png';
import GitbookImage from '../resources/assets/gitbook.svg';
import ExplorerImage from '../resources/assets/explorer.png';
import MediumImage from '../resources/assets/medium.png';

export type FooterProps = {
    className?: string;
};

const Container = styled.div`
  min-height: 100px;
  /* background: #ffbc42; */
  display: flex;
  align-items: center; // To center the content vertically
`;

const InnerContainer = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 1rem;
  display: flex;
  justify-content: space-between; // To space out the social links and other content
`;

const SocialLinks = styled.div`
  display: flex;
  justify
  gap: 4rem; // Space between the social links
`;

const Footer: React.FC<FooterProps> = ({ className }) => {
    return (
        <Container className={className}>
            <InnerContainer>
                <SocialLinks className='flex justify-center gap-12'>
                    <a
                        href="https://twitter.com/ACCU_DeFi"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={TwitterImage} alt="Twitter" />
                    </a>
                    <a
                        href="https://discord.gg/TwUrBAcQ3y"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={DiscordImage} alt="Discord" />
                    </a>
                    <a
                        href="https://medium.com/@accumulator.crypto"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={MediumImage} style={{ width: 32 }} alt="Medium" />
                    </a>
                    <a
                        href="https://accumulator.gitbook.io/accumulator/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={GitbookImage} style={{ width: 32 }} alt="Gitbook" />
                    </a>
                    <a
                        href="https://explorer.evm.shimmer.network/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={ExplorerImage} alt="Explorer" />
                    </a>
                </SocialLinks>
            </InnerContainer>
        </Container>
    );
};

export default Footer;
