import axios from 'axios';

class PotluckApiPricesService {

    constructor(private baseUrl: string) { }

    public async getTokenPrices(tokens: string[]): Promise<Map<string, number>> {
        if (tokens.length === 0) {
            return new Map();
        }

        const { data } = await axios.get(`${this.baseUrl}/prices`);
        return this.mapResults(tokens, data);
    }

    public async getLpPrices(lps: string[]): Promise<Map<string, number>> {
        if (lps.length === 0) {
            return new Map();
        }

        const { data } = await axios.get(`${this.baseUrl}/lps`);
        return this.mapResults(lps, data);
    }

    private mapResults(keys: string[], data: { [key: string]: number }): Map<string, number> {
        const map = new Map<string, number>();
        keys.forEach(key => {
            if (key in data) {
                map.set(key, data[key]);
            }
        });
        return map;
    }

}

export default PotluckApiPricesService;