import styled from "styled-components";

export type SummaryBlockProps = {
  label: string;
  value: string;
}

const Root = styled.div`
  text-align: left;
  width: 100%;
  position: relative;
  background-color: #73d2de;
  border-radius: 0.5rem;
  padding: 1rem;
  border: 0.2rem solid #80e4f1;
  opacity: 0.9;
`;

const Label = styled.h2`
  text-transform: uppercase;
  font-weight: bold;
  font-size: .8rem;
`;

const Value = styled.span`
  font-size: 1.8rem;
`;

function SummaryBlock({ label, value }: SummaryBlockProps) {
  return (
    <Root>
      <Label>{label}</Label>
      <Value>{value}</Value>
    </Root>

  )
}

export default SummaryBlock;