import BigNumber from "bignumber.js";
import { RootState } from "../../../../store";
import { BIGNUMBER_ZERO } from "../../../../utils/bignumber";
import { isNumeric } from "../../../../utils/numbers";
import { VaultOracle } from "../../../../services/vaults/Vault";

export const selectPriceForVault = (state: RootState, vaultId: string, oracleType: VaultOracle): BigNumber => {
  const vault = state.vaults.vaults.find(vault => vault.id === vaultId);
  if (!vault) {
    return BIGNUMBER_ZERO;
  }

  const oraclePriceNumber = oracleType === VaultOracle.Lp ? state.prices.lps[vault?.oracle.id] : state.prices.tokens[vault?.oracle.id];
  return isNumeric(oraclePriceNumber) ? new BigNumber(oraclePriceNumber) : BIGNUMBER_ZERO;
}