import { Platform, PlatformIdentifier } from "./Platform";

const platforms: Platform[] = [{
    id: PlatformIdentifier.SpookySwap,
    name: 'SpookySwap',
    url: 'https://spooky.fi',
    icon: '/images/platforms/spookyswap.svg',
    description: 'SpookySwap is an all-in-one decentralized exchange for leveraging diversified funds across ecosystems, with the speed of Fantom Opera.'
}, {
    id: PlatformIdentifier.SpiritSwap,
    name: 'SpiritSwap',
    url: 'https://www.spiritswap.finance',
    icon: '/images/platforms/spiritswap.png',
    description: 'The SpiritSwap protocol captures the essence of everything Defi 2.0 on the Fantom network, delivering a complete hub for trading, lending and borrowing, reward sharing, staking delegations, and farming.'
}, {
    id: PlatformIdentifier.MagicSea,
    name: 'MagicSea',
    url: 'https://magicsea.finance/',
    icon: '/images/platforms/MagicSea.png',
    description: 'MagicSea is a leading decentralized exchange (DEX) on IOTA focused on offering a premier trading experience.'
}]

export const findPlatform = (identifier: PlatformIdentifier): Platform => platforms.find(item => item.id === identifier) || {} as Platform;

export default platforms;