import IDatabarnService, { DatabarnPriceType, PriceSerieItem, Timebucket, TimelineItem } from "./IDatabarnService";

type RawTimelineItem = {
  datetime: string;
  product_key: string;
  display_name: string;
  chain: string;
  is_eol: boolean;
  is_dashboard_eol: boolean;
  transaction_hash: string;
  share_to_underlying_price: number;
  underlying_to_usd_price: number;
  share_balance: number;
  share_diff: number;
  underlying_balance: number;
  usd_balance: number;
  underlying_diff: number;
  usd_diff: number;
}

class PotluckDatabarnService implements IDatabarnService {

  constructor(private apiBaseUrl: string) { }

  public async getTimeline(wallet: string): Promise<TimelineItem[]> {
    const searchParams = new URLSearchParams({ address: wallet });
    const endpointUrl = `${this.apiBaseUrl}/api/v1/accu/timeline?${searchParams.toString()}`;
    const res = await fetch(endpointUrl, { headers: { 'Accept': 'application/json' } });
    if (!res.ok) {
      return [];
    }

    const data = await res.json();
    return data.map(this.mapRawToTimelineItem);
  }

  public async getPrice(productKey: string, type: DatabarnPriceType, timeBucket: Timebucket): Promise<PriceSerieItem[]> {
    const searchParams = new URLSearchParams({ product_key: productKey, price_type: type, time_bucket: timeBucket })
    const endpointUrl = `${this.apiBaseUrl}/api/v1/price?${searchParams.toString()}`;
    const res = await fetch(endpointUrl, { headers: { 'Accept': 'application/json' } });
    if (!res.ok) {
      return [];
    }

    const data = await res.json();
    return data.map(this.mapRawToPriceSerieItem);
  }


  private mapRawToTimelineItem(rawTimelineItem: RawTimelineItem): TimelineItem {
    return {
      chain: rawTimelineItem.chain,
      datetime: rawTimelineItem.datetime,
      displayName: rawTimelineItem.display_name,
      isDashboardEol: rawTimelineItem.is_dashboard_eol,
      isEol: rawTimelineItem.is_eol,
      productKey: rawTimelineItem.product_key,
      shareBalance: rawTimelineItem.share_balance,
      shareToUnderlyingPrice: rawTimelineItem.share_to_underlying_price,
      shareDiff: rawTimelineItem.share_diff,
      transactionHash: rawTimelineItem.transaction_hash,
      underlyingBalance: rawTimelineItem.underlying_balance,
      underlyingDiff: rawTimelineItem.underlying_diff,
      underlyingToUsdPrice: rawTimelineItem.underlying_to_usd_price,
      usdBalance: rawTimelineItem.usd_balance,
      usdDiff: rawTimelineItem.usd_diff
    }
  }

  private mapRawToPriceSerieItem(rawPriceSerieItem: [string, string, string, string, string]): PriceSerieItem {
    return {
      datetime: rawPriceSerieItem[0],
      openPrice: rawPriceSerieItem[1],
      lowPrice: rawPriceSerieItem[2],
      highPrice: rawPriceSerieItem[3],
      closePrice: rawPriceSerieItem[4]
    }
  }

}

export default PotluckDatabarnService;