import { Asset, AssetIdentifier } from './Asset';

const assets: Asset[] = [
  {
    id: AssetIdentifier.FANG,
    icon: '/images/assets/FANG.png',
    name: 'FANG',
  },
  {
    id: AssetIdentifier.FTM,
    icon: '/images/assets/FTM.png',
    name: 'FTM',
  },
  {
    id: AssetIdentifier.shimmer,
    icon: '/images/assets/SMRSEA.svg',
    name: 'SMR',
  },
  {
    id: AssetIdentifier.LUM,
    icon: '/images/assets/LUMSEA.svg',
    name: 'LUM',
  },
  {
    id: AssetIdentifier.DEEPR,
    icon: '/images/assets/DEEPR.svg',
    name: 'DEEPR',
  },
  {
    id: AssetIdentifier.USDT,
    icon: '/images/assets/USDT.svg',
    name: 'USDT',
  },
  {
    id: AssetIdentifier.ETH,
    icon: '/images/assets/ETH.svg',
    name: 'ETH',
  },
  {
    id: AssetIdentifier.APEin,
    icon: '/images/assets/APEin.svg',
    name: 'APEin',
  },
  {
    id: AssetIdentifier.sIOTA,
    icon: '/images/assets/sIOTA.svg',
    name: 'sIOTA',
  },
  {
    id: AssetIdentifier.RUST,
    icon: '/images/assets/RUST.svg',
    name: 'RUST',
  },
  {
    id: AssetIdentifier.MLUM,
    icon: '/images/assets/MLUM.svg',
    name: 'MLUM',
  },
  {
    id: AssetIdentifier.IOTA,
    icon: '/images/assets/WIOTA.svg',
    name: 'IOTA',
  },
  {
    id: AssetIdentifier.USDC,
    icon: '/images/assets/USDC.svg',
    name: 'USDC',
  },
];

export const findAsset = (assetIdentifier: AssetIdentifier): Asset | null =>
  assets.find((asset) => asset.id === assetIdentifier) || null;

export default assets;
