import styled from "styled-components"

export type NotConnectedProps = {
    className?: string;
}

const Root = styled.div`
    font-weight: 700;
    font-size: 1.25rem;
    text-align: center;
`;

const NotConnected: React.FC<NotConnectedProps> = ({
    className
}) => (
    <Root className={className}>Wallet not connected</Root>
)

export default NotConnected;