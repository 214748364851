import Network from '../Network';

export const SHIMMER_NETWORK_ID = 148;
const SHIMMER_PUBLIC_RPC = 'https://json-rpc.evm.shimmer.network/';

export const shimmer: Network = {
  id: SHIMMER_NETWORK_ID,
  chainIdentifier: 'shimmer',
  publicRpcUrl: SHIMMER_PUBLIC_RPC,
  name: 'Shimmer',
  currency: 'SMR',
  explorerUrl: 'https://explorer.evm.shimmer.network',
  testnet: false,
};
