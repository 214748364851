import Vault, { VaultOracle } from "../../../../../../../services/vaults/Vault";
import countTotalValue from "../../../../../../../utils/countTotalValue";
import { PriceObject } from "../../../../../../prices/redux/pricesSlice";
import { BalancesMap } from "../../../../../redux/vaultsSlice";
import SortDirection, { flipIfAsc } from "./direction";

const createVaultTvlCounter = (
    balancesMap: BalancesMap,
    lpPrices: PriceObject,
    tokenPrices: PriceObject,
) => {
    return (vault: Vault): number => {
        const { id, oracle } = vault;
        return countTotalValue(
            balancesMap[id].totalBalance || 0,
            vault.token.decimals,
            oracle.type === VaultOracle.Lp
                ? lpPrices[oracle.id]
                : tokenPrices[oracle.id]
        );
    }
}

const sortByTvl = (
    vaults: Vault[],
    balancesMap: BalancesMap,
    lpPrices: PriceObject,
    tokenPrices: PriceObject,
    sortDirection: SortDirection
): Vault[] => {
    const tmpVaults = [...vaults];
    const tvlCounter = createVaultTvlCounter(balancesMap, lpPrices, tokenPrices);
    return tmpVaults.sort((v1, v2) => flipIfAsc(tvlCounter(v2) - tvlCounter(v1), sortDirection));
}

export default sortByTvl;