import { useCallback } from 'react';
import styled from "styled-components";
import { BiArrowBack } from "react-icons/bi";
import { To, useLocation, useNavigate } from 'react-router';
import classNames from 'classnames';

export type BackButtonProps = {
    className?: string;
    defaultNavigateTo?: string;
}

const Button = styled.button`
    color: #000;
`;

const resolveTo = (defaultNavigateTo: string, lastLocation?: Location): To => {
    if (lastLocation?.pathname) {
        return {
            pathname: lastLocation.pathname,
            search: lastLocation.search
        }
    }
    return defaultNavigateTo;
}

const BackButton = ({
    defaultNavigateTo = '/',
    className
}: BackButtonProps) => {
    const navigate = useNavigate();
    const location = useLocation();

    const handleClick = useCallback(() => {
        const to = resolveTo(defaultNavigateTo, location.state?.from);
        navigate(to, { state: { from: location } });
    }, [navigate, location, defaultNavigateTo]);

    const rootClasses = classNames('inline-flex', 'items-center', className);

    return (
        <Button className={rootClasses} onClick={handleClick}>
            <BiArrowBack size={20} className='mr-1' />
            <span>Back</span>
        </Button>
    )
}

export default BackButton;