import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import useScreenSize from "../../hooks/useScreenSize";
import { isSmallerOrEqualTo } from "../../utils/screenSizes";
import TotalTVL from "./TotalTVL";
import Wallet from "./Wallet";

export type HeaderProps = {
  className?: string;
}

const Logo = styled.img`
    width: 100%;
    max-width: 400px;
`;

const Header: React.FC<HeaderProps> = ({
  className
}) => {
  const screenSize = useScreenSize();
  const location = useLocation();
  const isSmallScreen = isSmallerOrEqualTo(screenSize, 'sm');

  const renderContent = () => {
    if (isSmallScreen) {
      return (
        <div key='small'>
          <Link className="block" to='/' state={{ from: location }}>
            <Logo className="mx-auto" src='/images/logo.png' />
          </Link>
          <div className='mt-4 mb-8' >
            <Wallet align="center" />
          </div>
        </div>
      );
    }

    return (
      <div key='large' className="grid grid-cols-8 items-center">
        <div className="col-span-2">
          <TotalTVL />
        </div>
        <Link to='/' className="col-span-4 flex justify-center">
          <Logo src='/images/logo.png' />
        </Link>
        <div className="col-span-2">
          <Wallet align='right' />
        </div>
      </div>
    );
  }

  return (
    <div className={className}>
      {renderContent()}
    </div>
  )
}

export default Header;