import { useEffect } from 'react';
import useAppDispatch from '../../../../hooks/useAppDispatch';
import { fetchWalletTimeline } from '../../redux/actions/fetchWallletTimeline';
import { useSelector } from 'react-redux';
import { selectWalletGlobalStats } from '../../redux/selectors/selectWalletGlobalStats';
import { selectTotalYieldUsd, selectVaultPnls } from '../../redux/selectors';
import useWallet from '../../../wallet/hooks/useWallet';
import { RootState } from '../../../../store';
import useVaults from '../../../vaults/hooks/useVaults';
import fetchPrices from '../../../prices/redux/fetchPrices';
import fetchBalances from '../../../vaults/redux/fetchBalances';
import styled from 'styled-components';
import BackButton from '../../../vaults/views/Vault/components/BackButton';
import SummaryBlock from './components/SummaryBlock';
import toFriendlyBigUsd from '../../../../utils/toFriendlyBigUsd';
import { selectUserDepositedVaults } from '../../../vaults/redux/selectors';
import DashboardVaultList from './components/DashboardVaultList';
import useAppSelector from '../../../../hooks/useAppSelector';

const Container = styled.div`
    margin: 0 auto;
`;

const ContentContainer = styled.div`
    background-color: #73D2DE;
    border-radius: 1rem;
`;

const ContentBody = styled.div`
    position: relative;
    padding: 1rem;
`;

const ContentHeader = styled.h2`
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
    background-color: #8F2D56;
    padding: 1rem;
    font-size: 1.15rem;
`;

const SummaryContainer = styled.div`
    background-color: rgba(0,0,0,0.4);
    border-radius: 1rem;
`;

const NotConnected = styled.div`
  text-align: center;
  font-size: 1.75rem;
  color: #000;
`;

function Dashboard() {
  const dispatch = useAppDispatch();
  const wallet = useWallet();
  const { vaultsInitialized, fetchVaults, vaults } = useVaults();
  const userDepositedVaults = useSelector(selectUserDepositedVaults);
  const walletStats = useSelector(selectWalletGlobalStats);
  const totalUsdYield = useSelector((state: RootState) => selectTotalYieldUsd(state, wallet.address));
  const balancesMap = useAppSelector(state => state.vaults.balancesMap);
  const apysMap = useSelector((state: RootState) => state.vaults.apysMap);
  const pnlMap = useSelector(selectVaultPnls);

  useEffect(() => {
    if (!vaultsInitialized) {
      fetchVaults();
    }
  }, []);

  useEffect(() => {
    const vaultIds = vaults.map(vault => vault.id);
    if (vaultIds.length > 0 && wallet.connected) {
      dispatch(fetchPrices());
      dispatch(fetchBalances(vaultIds));
    }
  }, [vaults, wallet.connected]);

  useEffect(() => {
    if (wallet.address) {
      dispatch(fetchWalletTimeline({ walletAddress: wallet.address }));
    }
  }, [wallet.address]);

  const renderContent = () => {
    if (!wallet.connected) {
      return <NotConnected className='mt-8'>Dashboard is visible only for connected users,<br />please connect your wallet.</NotConnected>
    }

    return (
      <>
        <SummaryContainer className="mb-8">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 p-4">
            <SummaryBlock label='Total deposit' value={toFriendlyBigUsd(walletStats.deposited)} />
            <SummaryBlock label='Vaults' value={walletStats.depositedVaults.toString()} />
            <SummaryBlock label='Accrued yield' value={toFriendlyBigUsd(totalUsdYield.toNumber(), 4)} />
            <SummaryBlock label='Est. daily yield' value={toFriendlyBigUsd(walletStats.daily, 4)} />
          </div>
        </SummaryContainer>

        <ContentContainer>
          <ContentHeader>Vaults</ContentHeader>
          <ContentBody>
            <DashboardVaultList
              vaults={userDepositedVaults}
              apysMap={apysMap}
              pnlMap={pnlMap}
              balancesMap={balancesMap}
            />
          </ContentBody>
        </ContentContainer>
      </>
    )
  }

  return (
    <Container>
      <div>
        <BackButton />
      </div>
      {renderContent()}
    </Container>
  )



}

export default Dashboard;