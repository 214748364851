import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import useAppDispatch from "../../hooks/useAppDispatch";
import { RootState } from "../../store";
import fetchBalances from "./redux/fetchBalances";

export type VaultBalancesPollingContainerProps = {
    pollingIntervalMs: number;
}

const VaultBalancesPollingContainer: React.FC<VaultBalancesPollingContainerProps> = ({
    pollingIntervalMs
}) => {
    const dispatch = useAppDispatch();
    const { vaults } = useSelector((state: RootState) => state.vaults);
    const { address } = useSelector((state: RootState) => state.wallet);

    const boundAction = useCallback(() => {
        dispatch(fetchBalances(vaults.map(v => v.id), true));
    }, [dispatch, vaults]);

    useEffect(() => {
        boundAction();

        const handle = setInterval(() => {
            boundAction();
        }, pollingIntervalMs);

        return () => {
            clearInterval(handle);
        }
    }, [boundAction, pollingIntervalMs, address]);

    return <></>
}

export default VaultBalancesPollingContainer;