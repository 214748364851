import { BigNumber, Contract } from "ethers";
import { AppDispatch, GetAppState } from "../../../store";
import getSignerOrProvider from "../../wallet/getSignerOrProvider";
import abi from "../abis/vault";
import { depositFailed, deposited, depositing } from "./vaultsSlice";
import { NotificationType, notificationAdded } from "../../notifications/redux/notificationsSlice";
import { resolveFriendlyErrorMessage } from "../../notifications/redux/utils";
import fetchBalances from "./fetchBalances";

const deposit = (vaultId: string, amount: BigNumber) => {
  return async (dispatch: AppDispatch, getState: GetAppState) => {
    const { vaults, wallet } = getState();
    if (!wallet.connected) {
      return;
    }

    const vault = vaults.vaults.find(v => v.id === vaultId);
    if (!vault) {
      return;
    }

    dispatch(depositing(vaultId));

    const contract = new Contract(vault.earn.tokenAddress, abi, getSignerOrProvider(vault.networkId, wallet));
    try {
      const tx = await contract.deposit(amount);
      await tx.wait();

      dispatch(fetchBalances([vaultId]));
      dispatch(deposited(vaultId));
    } catch (e: any) {
      console.error(`Calling deposit for vault ${vaultId}`, e);
      dispatch(depositFailed({ id: vaultId }));
      dispatch(notificationAdded({ title: 'Error on deposit', message: resolveFriendlyErrorMessage(e?.message || ''), type: NotificationType.error }));
    }
  }
}

export default deposit;