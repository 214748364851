import styled from "styled-components"

export type WrongChainProps = {
    className?: string;
}

const Root = styled.div`
    font-weight: 700;
    font-size: 1.25rem;
    text-align: center;
`;

const WrongChain: React.FC<WrongChainProps> = ({
    className
}) => (
    <Root className={className}>Connected to wrong chain</Root>
)

export default WrongChain;