import type BigNumber from 'bignumber.js';
import { isAfter, isBefore, isEqual, max, subDays } from 'date-fns';
import sortBy from 'lodash/sortBy';
import { roundDownMinutes } from './date';
import { timeBucketToSamplingPeriod, samplingPeriodMs } from './time-bucket'
import { PriceSerieItem, Timebucket } from '../services/databarn/IDatabarnService';
import { toBigNumber, BIGNUMBER_ZERO } from './bignumber';
import { VaultTimelineEntity } from '../features/dashboard/redux/dashboardSlice';

type PriceRow = { date: string, value: BigNumber };

// simulate a join between the 3 price series locally
export interface PriceTsRow {
  datetime: number;
  shareBalance: number | null
  underlyingBalance: number | null;
  usdBalance: number | null;
}

function sortAndTransform(
  prices: PriceSerieItem[],
  currentPrice: BigNumber
): PriceRow[] {
  const oneDayAgo = subDays(new Date(), 1);
  return sortBy(prices, 'date').map(
    (serieItem) => ({
      date: serieItem.datetime,
      value: toBigNumber(serieItem.openPrice) ?? (isBefore(serieItem.datetime, oneDayAgo) ? BIGNUMBER_ZERO : currentPrice),
    })
  );
}

export function getInvestorTimeserie(
  timeBucket: Timebucket,
  timeline: VaultTimelineEntity[],
  sharesToUnderlying: PriceSerieItem[],
  underlyingToUsd: PriceSerieItem[],
  firstDate: Date,
  currentPpfs: BigNumber,
  currentPrice: BigNumber,
  currentShareBalance: BigNumber
): PriceTsRow[] {
  // so, first we need to generate datetime keys for each row
  const { bucketSize: bucketSizeStr, timeRange: timeRangeStr } =
    timeBucketToSamplingPeriod(timeBucket);
  const bucketSize = samplingPeriodMs[bucketSizeStr];
  const timeRange = samplingPeriodMs[timeRangeStr];

  const lastDate = new Date(Math.floor(new Date().getTime() / bucketSize) * bucketSize);
  const firstDate1 = new Date(lastDate.getTime() - timeRange);

  const fixedDate = max([firstDate, firstDate1]);

  // Use the current price to fill in any missing prices in the past 24 hours (otherwise set to 0)
  const sortedSharesToUnderlying = sortAndTransform(sharesToUnderlying, currentPpfs);
  const sortedUnderlyingToUsd = sortAndTransform(underlyingToUsd, currentPrice);

  let balanceIdx = 0;
  let sharesIdx = 0;
  let underlyingIdx = 0;
  let currentDate = fixedDate;

  const pricesTs: PriceTsRow[] = [];

  //We should be adding precise initial ppfs and price as first data point
  if (isEqual(timeline[0].datetime, fixedDate)) {
    pricesTs.push({
      datetime: roundDownMinutes(timeline[0].datetime).getTime(),
      shareBalance: toBigNumber(timeline[0].shareBalance).toNumber(),
      underlyingBalance: toBigNumber(timeline[0].shareBalance)
        .times(timeline[0].shareToUnderlyingPrice)
        .toNumber(),
      usdBalance: toBigNumber(timeline[0].shareBalance)
        .times(
          toBigNumber(timeline[0].shareToUnderlyingPrice).times(timeline[0].underlyingToUsdPrice || BIGNUMBER_ZERO)
        )
        .toNumber(),
    });
    currentDate = new Date(currentDate.getTime() + bucketSize);
  }

  // Need at least one row in each series to work from
  if (sortedSharesToUnderlying.length && sortedUnderlyingToUsd.length) {
    while (currentDate <= lastDate) {
      // add a row for each date
      // find the corresponding balance row
      while (
        balanceIdx < timeline.length - 1 &&
        isAfter(currentDate, timeline[balanceIdx + 1].datetime)
      ) {
        balanceIdx++;
      }
      // find the corresponding shares row
      while (
        sharesIdx < sortedSharesToUnderlying.length - 1 &&
        isAfter(currentDate, sortedSharesToUnderlying[sharesIdx + 1].date)
      ) {
        sharesIdx++;
      }
      // find the corresponding underlying row
      while (
        underlyingIdx < sortedUnderlyingToUsd.length - 1 &&
        isAfter(currentDate, sortedUnderlyingToUsd[underlyingIdx + 1].date)
      ) {
        underlyingIdx++;
      }

      // now we have the correct rows for this date
      const shareBalance = toBigNumber(timeline[balanceIdx].shareBalance);
      if (shareBalance && !shareBalance.isEqualTo(BIGNUMBER_ZERO)) {
        // Shares to underlying
        const shares = sortedSharesToUnderlying[sharesIdx];
        const underlyingBalance = shareBalance.times(shares.value);
        // Underlying to usd
        const underlying = sortedUnderlyingToUsd[underlyingIdx];
        const usdBalance = underlyingBalance.times(underlying.value);

        pricesTs.push({
          //return date on seconds
          datetime: currentDate.getTime(),
          shareBalance: shareBalance.toNumber(),
          underlyingBalance: underlyingBalance.toNumber(),
          usdBalance: usdBalance.toNumber(),
        });
      }

      currentDate = new Date(currentDate.getTime() + bucketSize);
    }
  }

  pricesTs.push({
    //round down our to the last hours, since first item of the api do the same
    datetime: roundDownMinutes(new Date()).getTime(),
    shareBalance: currentShareBalance.toNumber(),
    underlyingBalance: currentShareBalance.times(currentPpfs).toNumber(),
    usdBalance: currentShareBalance.times(currentPpfs).times(currentPrice).toNumber(),
  });

  return pricesTs;
}
