import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type PriceObject = { [id: string]: number };

export type PricesState = {
    tokens: PriceObject;
    lps: PriceObject;
    initialized: boolean;
    loading: boolean;
}

const initialState: PricesState = {
    tokens: {},
    lps: {},
    initialized: false,
    loading: false
}

const pricesSlice = createSlice({
    name: 'prices',
    initialState,
    reducers: {
        pricesLoading: (state) => ({
            ...state,
            loading: true
        }),
        pricesUpdated: (state, action: PayloadAction<{ lps: PriceObject, tokens: PriceObject }>) => ({
            ...state,
            loading: false,
            initialized: true,
            lps: action.payload.lps,
            tokens: action.payload.tokens
        }),
    }
});

export const {
    pricesLoading,
    pricesUpdated,
} = pricesSlice.actions

export default pricesSlice.reducer;