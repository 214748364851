import { useEffect, useState } from 'react';
import useVaults from "../../hooks/useVaults";
import useAppDispatch from '../../../../hooks/useAppDispatch';
import fetchPrices from '../../../prices/redux/fetchPrices';
import Vault, { VaultOracle } from '../../../../services/vaults/Vault';
import { useParams } from 'react-router';
import styled from 'styled-components';
import useBalance from '../../hooks/useBalance';
import countTotalValue from '../../../../utils/countTotalValue';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { yearlyToDaily } from '../../../../utils/apyConversions';
import toFriendlyBigUsd from '../../../../utils/toFriendlyBigUsd';
import TransactContainer from './components/TransactContainer/TransactContainer';
import IconSet from './components/TransactContainer/Icons';
import BackButton from './components/BackButton';
import ContentBlock from '../../../../components/ContentBlock';
import { toFriendlyApy } from '../../../../utils/toFriendlyApy';
import NetworkIcon from '../../../../components/NetworkIcon';
import getNetwork from '../../../../networks/getNetwork';
import { selectVaultPnl } from '../../../dashboard/redux/selectors';
import useWallet from '../../../wallet/hooks/useWallet';
import { fetchWalletTimeline } from '../../../dashboard/redux/actions/fetchWallletTimeline';
import { formatLargeUsd, formatTokenDisplayCondensed } from '../../../../utils/format';
import { BIGNUMBER_ZERO, BigNumberish, toBigNumber } from '../../../../utils/bignumber';
import PnLGraph from './components/PnLGraph/PnLGraph';
import useAppSelector from '../../../../hooks/useAppSelector';
import selectUserHasDepositInVault from '../../redux/selectors/selectUserHasDepositInVault';
import { selectHasDataToShowGraphByVaultId } from '../../../dashboard/redux/selectors/selectHasDataToShowInGraphByVaultId';
import countDepositedBalance from './utils/countDepositedBalance';

export type ListVaultsProps = {
  className?: string;
}

const Container = styled.div`
  margin: 0 auto;
`;

const VaultInfoContainer = styled.div`
  position: relative;
`;

const ContentContainer = styled.div`
  background-color: #73D2DE;
  border-radius: 1rem;
`;

const ContentBody = styled.div`
  position: relative;
  padding: 1rem;
`;

const Header = styled.h1`
  font-size: 2rem;
  text-align: left;
  font-weight: bold;
`;

const ContentHeader = styled.h2`
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
  background-color: #8F2D56;
  padding: 1rem;
  font-size: 1.15rem;
`;

const ContentFooter = styled.div`
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
  background-color: rgba(255, 255, 255, 0.5);
`;

const BannerImage = styled.img`
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
  width: 100%;
`;

const Description = styled.p``;

const RightColumn = styled.div`
  max-width: 450px;
  width: 100%;
`;

const PositionedNetworkIcon = styled(NetworkIcon)`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%,-75%);
`;

const DoNotHurry = styled.div`
  max-width: 700px;
  font-size: 1.1rem;
  text-align: center;
  font-style: italic;
`;

const formatLargeUsdDisplay = (value?: BigNumberish) => {
  const bn = toBigNumber(value ?? 0);
  const decimals = bn.gte(1) ? 2 : 4;
  return formatLargeUsd(bn, decimals);
}

const formatTokenDisplayFactory = (decimals: number) => (value?: BigNumberish) => formatTokenDisplayCondensed(value ?? BIGNUMBER_ZERO, decimals);

const VaultView: React.FC<ListVaultsProps> = ({
  className
}) => {
  const dispatch = useAppDispatch();
  const wallet = useWallet();
  const { vaultId } = useParams();
  const { vaults: allVaults, fetchVaults } = useVaults();
  const { apysMap, initialized: vaultsInitialized } = useSelector((state: RootState) => state.vaults);
  const { connected: isWalletConnected } = useSelector((state: RootState) => state.wallet);
  const { lps, tokens, initialized: pricesInitialized } = useSelector((state: RootState) => state.prices);
  const { balances } = useBalance(vaultId || '');
  const [vault, setVault] = useState<Vault | null>(null);
  const network = vault ? getNetwork(vault.networkId) : null;
  const userHasDeposit = useAppSelector(state => !!vault && selectUserHasDepositInVault(state, vault.id));
  const pnl = useSelector((state: RootState) => vault ? selectVaultPnl(state, vault.id) : null);
  const hasTimelineData = useAppSelector(state =>
    selectHasDataToShowGraphByVaultId(state, vaultId ?? '', wallet.address)
  );

  useEffect(() => {
    if (allVaults.length === 0) {
      fetchVaults();
    }
  }, [])

  useEffect(() => {
    dispatch(fetchPrices());
  }, [vault, dispatch]);

  useEffect(() => {
    if (wallet.address) {
      dispatch(fetchWalletTimeline({ walletAddress: wallet.address }));
    }
  }, [wallet.address]);

  useEffect(() => {
    setVault(allVaults.find(v => v.id === vaultId) || null);
  }, [allVaults, vaultId]);

  if (!vault) {
    return null;
  }

  const { oracle } = vault;
  const price = oracle.type === VaultOracle.Lp
    ? lps[oracle.id]
    : tokens[oracle.id];

  const isLoadingTvls = !balances.initialized || !pricesInitialized;

  const renderWalletContent = () => {
    const formatTokenDisplay = formatTokenDisplayFactory(vault.token.decimals);

    const renderYourDeposits = () => {
      if (!hasTimelineData) {
        const balance = countDepositedBalance(balances, vault.token.decimals, vault.earn.decimals);
        const usdBalance = countTotalValue(balance, vault.token.decimals, price);
        const shiftedBalance = toBigNumber(balance.toString()).shiftedBy(-vault.token.decimals);
        return (
          <>
            <ContentBlock label='Your deposit' value={formatTokenDisplay(shiftedBalance)} isLoading={!pnl} subValue={formatLargeUsdDisplay(usdBalance)} />
            <ContentBlock label='At deposit' value="-" subValue="-" isLoading={!pnl} isDisabled={true} />
            <ContentBlock label='Yield' value="-" subValue="-" isLoading={!pnl} isDisabled={true} />
            <ContentBlock label='PNL' value="-" isLoading={!pnl} isDisabled={true} />
          </>
        )
      }

      return (
        <>
          <ContentBlock label='Your deposit' value={formatTokenDisplay(pnl?.deposit)} isLoading={!pnl} subValue={formatLargeUsdDisplay(pnl?.depositUsd)} />
          <ContentBlock label='At deposit' value={formatTokenDisplay(pnl?.balanceAtDeposit)} isLoading={!pnl} subValue={formatLargeUsdDisplay(pnl?.usdBalanceAtDeposit)} />
          <ContentBlock label='Yield' value={formatTokenDisplay(pnl?.totalYield)} subValue={formatLargeUsdDisplay(pnl?.totalYieldUsd)} isLoading={!pnl} />
          <ContentBlock label='PNL' value={formatLargeUsdDisplay(pnl?.totalPnlUsd)} isLoading={!pnl} />
        </>
      )
    }

    return (
      <ContentContainer className='mt-8'>
        <ContentHeader>Your deposit</ContentHeader>
        <ContentBody>
          <div className='grid grid-cols-2 md:grid-cols-4 gap-4'>
            {renderYourDeposits()}
          </div>
          {!hasTimelineData && (
            <div className="flex justify-center mt-2">
              <DoNotHurry>
                Hold on to your nuts! It might take a bit of time for your data to scurry in after the initial deposit.
              </DoNotHurry>
            </div>
          )}
        </ContentBody>
        <ContentFooter>
          <PnLGraph vaultId={vault.id} address={wallet.address} />
        </ContentFooter>
      </ContentContainer >
    );
  }

  const tvl = countTotalValue(balances.totalBalance, vault.token.decimals, price);
  const apyValue = apysMap[vault.id];
  const apy = toFriendlyApy(apyValue)
  const daily = toFriendlyApy(yearlyToDaily(apyValue), 4);

  return (
    <Container>
      <div>
        <BackButton />
      </div>

      <div className='grid grid-cols-1 lg:grid-cols-3 gap-8'>
        <VaultInfoContainer className="lg:col-span-2">
          <ContentContainer>
            {vault.bannerImage && <BannerImage src={vault.bannerImage} />}
            <ContentBody>
              {network && (<PositionedNetworkIcon size={60} network={network} />)}
              <Header className='py-4 px-3 flex items-center'>
                <IconSet assets={vault.assets} size={50} />
                <div className='ml-3'>{vault.name}</div>
              </Header>
              <Description className='pt-4 pb-6'>
                Farm is auto-compounding, where earnings are automatically deposited every 4 hours. Deposit your <b>{vault.token.symbol}</b> and start earning more today.
              </Description>
              <div className='grid grid-cols-2 md:grid-cols-4 gap-4'>
                <ContentBlock label='TVL' value={toFriendlyBigUsd(tvl)} isLoading={isLoadingTvls} />
                <ContentBlock label='APY' value={apy} isLoading={!vaultsInitialized} />
                <ContentBlock label='Daily' value={daily} isLoading={!vaultsInitialized} />
              </div>
            </ContentBody>
          </ContentContainer>

          {isWalletConnected && userHasDeposit && renderWalletContent()}

          <ContentContainer className='mt-8'>
            <ContentHeader>
              About the platform: <b>{vault.host.name}</b>
            </ContentHeader>
            <ContentBody>
              <Description className='pt-4 pb-6'>{vault.host.description}</Description>
            </ContentBody>
          </ContentContainer>
        </VaultInfoContainer>

        <RightColumn className='mx-auto'>
          <TransactContainer vault={vault} balances={balances} />
        </RightColumn>
      </div>
    </Container>
  );
}

export default VaultView;