import { BigNumber, Contract } from "ethers";
import { AppDispatch, GetAppState } from "../../../store";
import getSignerOrProvider from "../../wallet/getSignerOrProvider";
import abi from "../abis/vault";
import { approveFailed, approved, approving, manuallyUpdated } from "./vaultsSlice";
import { NotificationType, notificationAdded } from "../../notifications/redux/notificationsSlice";
import { resolveFriendlyErrorMessage } from "../../notifications/redux/utils";

const approve = (vaultId: string, amount: BigNumber) => {
    return async (dispatch: AppDispatch, getState: GetAppState) => {
        const { vaults, wallet } = getState();
        if (!wallet.connected) {
            return;
        }

        const vault = vaults.vaults.find(v => v.id === vaultId);
        if (!vault) {
            return;
        }

        dispatch(approving(vaultId));
        try {
            const contract = new Contract(vault.token.tokenAddress, abi, getSignerOrProvider(vault.networkId, wallet));
            const tx = await contract.approve(vault.earn.tokenAddress, amount);
            await tx.wait();

            dispatch(manuallyUpdated({
                partialBalanceItem: {
                    walletAllowance: amount.toString()
                },
                vaultId,
            }));
            dispatch(approved(vaultId));
        } catch (e: any) {
            console.error(`Calling approve for vault ${vaultId}`, e);
            dispatch(approveFailed({ id: vaultId }));
            dispatch(notificationAdded({ title: 'Error on approve', message: resolveFriendlyErrorMessage(e?.message || ''), type: NotificationType.error }));
        }
    }
}

export default approve;