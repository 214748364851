import { AppDispatch } from "../../../store";
import { walletDisconnected, walletDisconnecting } from "./walletSlice";

const disconnectWallet = () => {
    return async (dispatch: AppDispatch) => {
        dispatch(walletDisconnecting());
        try {
            //await web3Modal.disconnect();
            dispatch(walletDisconnected());
        } catch (error) {
            console.error("Error when disconnecting wallet", error);
            //   dispatch({ type: HOME_DISCONNECT_WALLET_FAILURE }); 
        }
    }
}

export default disconnectWallet;
