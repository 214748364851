import styled from "styled-components";

const Button = styled.button<{}>`
    background-color: #8f2d56;
    width: 175px;
    border-radius: .5rem;
    text-transform: uppercase;
    color: #fff;
    font-size: 1rem;
    padding: .25rem;
    cursor: pointer;

    &:hover:not(:disabled) {
        background-color: #D81159;
    }

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
`;

export default Button;
