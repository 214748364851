import getVaultsService from "../../../services/vaults/getVaultsService";
import { AppDispatch } from "../../../store";
import mapToObject from "../../../utils/mapToObject";
import { apysUpdating, apysUpdated } from "./vaultsSlice";

const fetchApys = (vaultIds: string[]) => {
    return async (dispatch: AppDispatch) => {
        dispatch(apysUpdating());
        const vaultsService = getVaultsService();
        const apys = await vaultsService.getVaultApys(vaultIds);
        dispatch(apysUpdated(mapToObject(apys)))
    }
}

export default fetchApys;