import { useCallback } from "react";
import { useSelector } from "react-redux";
import useAppDispatch from "../../../hooks/useAppDispatch";
import Vault from "../../../services/vaults/Vault";
import { RootState } from "../../../store";
import fetchVaults from "../redux/fetchVaults";

export type UseVaultsResult = {
    vaultsLoading: boolean;
    vaultsInitialized: boolean;
    vaults: Vault[];
    fetchVaults: () => void;
}

const useVaults = (): UseVaultsResult => {
    const dispatch = useAppDispatch();
    const { loading, vaults, initialized } = useSelector((state: RootState) => state.vaults)

    const boundAction = useCallback(
        () => dispatch(fetchVaults()),
        [dispatch]
    );

    return {
        fetchVaults: boundAction,
        vaults,
        vaultsLoading: loading,
        vaultsInitialized: initialized
    }
}

export default useVaults;