import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import Vault from "../../../services/vaults/Vault";

export type BalancesMapItem = {
    updating: boolean,
    initialized: boolean;
    totalBalance: string;
    pricePerFullShare: string;
    walletBalance?: string;
    walletAllowance?: string;
    stakedBalance?: string;
}

export type BalancesMap = {
    [vaultId: string]: BalancesMapItem
}

export type ApysMap = Record<string, number>;

export type VaultsState = {
    vaults: Vault[];
    apysMap: ApysMap;
    apysUpdating: boolean;
    balancesMap: BalancesMap;
    depositing: string[];
    approving: string[];
    withdrawing: string[];
    loading: boolean;
    initialized: boolean;
}

const initialState: VaultsState = {
    vaults: [],
    apysMap: {},
    apysUpdating: false,
    balancesMap: {},
    depositing: [],
    approving: [],
    withdrawing: [],
    loading: false,
    initialized: false,
}

const createInitialBalancesMap = (updating: boolean): BalancesMapItem => ({
    updating,
    initialized: false,
    pricePerFullShare: '0',
    totalBalance: '0',
    walletAllowance: '0',
    walletBalance: '0',
    stakedBalance: '0'
})

const vaultsSlice = createSlice({
    name: 'vaults',
    initialState,
    reducers: {
        vaultsLoading: (state) => ({
            ...state,
            loading: true
        }),
        vaultsAdded: (state, action: PayloadAction<Vault[]>) => ({
            ...state,
            loading: false,
            vaults: action.payload
        }),

        apysUpdating: (state) => ({
            ...state,
            apysUpdating: true
        }),
        apysUpdated: (state, action: PayloadAction<{ [key: string]: number }>) => ({
            ...state,
            apysMap: action.payload,
            apysUpdating: false,
        }),

        balancesUpdating: (state, action: PayloadAction<string[]>) => {
            const vaultIds = action.payload;
            const newBalancesMap = { ...state.balancesMap };
            vaultIds.forEach(vaultId => {
                if (newBalancesMap[vaultId]) {
                    newBalancesMap[vaultId] = { ...newBalancesMap[vaultId], updating: true };
                } else {
                    newBalancesMap[vaultId] = createInitialBalancesMap(true);
                }
            });
            return {
                ...state,
                balancesMap: newBalancesMap
            }
        },
        balancesUpdated: (state, action: PayloadAction<{ [id: string]: Omit<BalancesMapItem, 'updating' | 'initialized'> }>) => {
            const newBalancesMap = { ...state.balancesMap };
            Object.keys(action.payload).forEach(vaultId => {
                newBalancesMap[vaultId] = {
                    ...action.payload[vaultId],
                    updating: false,
                    initialized: true
                }
            });
            return {
                ...state,
                balancesMap: newBalancesMap
            }
        },
        manuallyUpdated: (state, action: PayloadAction<{ vaultId: string, partialBalanceItem: Partial<BalancesMapItem> }>) => {
            const balancesMap = { ...state.balancesMap };
            const { partialBalanceItem, vaultId } = action.payload;
            if (balancesMap[vaultId]) {
                balancesMap[vaultId] = {
                    ...balancesMap[vaultId],
                    ...partialBalanceItem,
                };
            }
            return {
                ...state,
                balancesMap
            }
        },
        approving: (state, action: PayloadAction<string>) => ({
            ...state,
            approving: [...state.approving, action.payload]
        }),
        approved: (state, action: PayloadAction<string>) => ({
            ...state,
            approving: state.approving.filter(id => id !== action.payload)
        }),
        approveFailed: (state, action: PayloadAction<any>) => ({
            ...state,
            approving: state.approving.filter(id => id !== action.payload.id)
        }),
        depositing: (state, action: PayloadAction<string>) => ({
            ...state,
            depositing: [...state.depositing, action.payload]
        }),
        deposited: (state, action: PayloadAction<string>) => ({
            ...state,
            depositing: state.depositing.filter(id => id !== action.payload)
        }),
        depositFailed: (state, action: PayloadAction<any>) => ({
            ...state,
            depositing: state.depositing.filter(id => id !== action.payload.id)
        }),
        withdrawing: (state, action: PayloadAction<string>) => ({
            ...state,
            withdrawing: [...state.withdrawing, action.payload]
        }),
        withdrew: (state, action: PayloadAction<string>) => ({
            ...state,
            withdrawing: state.withdrawing.filter(id => id !== action.payload)
        }),
        withdrawFailed: (state, action: PayloadAction<any>) => ({
            ...state,
            withdrawing: state.withdrawing.filter(id => id !== action.payload.id)
        }),
        initialized: (state) => ({
            ...state,
            initialized: true
        }),
    }
});

export const {
    vaultsLoading,
    vaultsAdded,
    apysUpdating,
    apysUpdated,
    initialized,
    balancesUpdating,
    balancesUpdated,
    manuallyUpdated,
    approving,
    approved,
    approveFailed,
    depositing,
    deposited,
    depositFailed,
    withdrawing,
    withdrew,
    withdrawFailed
} = vaultsSlice.actions

export default vaultsSlice.reducer;