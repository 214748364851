import styled from "styled-components";
import Loading from "./Loading";

export type ContentBlockProps = {
    className?: string;
    label: string;
    value: string;
    subValue?: string;
    isLoading?: boolean;
    isDisabled?: boolean;
}

const Container = styled.div<{ isDisabled: boolean }>`
    border-radius: .25rem;
    padding: .5rem .85rem;
    color: #561317;
    background-color: rgba(255,255,255,0.5);
    ${props => props.isDisabled ? 'opacity: 0.3;' : ''}
`;

const Label = styled.div`
    font-size: .85rem;
    text-transform: uppercase;
`;

const Value = styled.div`
    position: relative;
    font-size: 1.35rem;
    line-height: 1.5rem;
    overflow: hidden;
`;

const SubValue = styled.div`
    position: relative;
    font-size: .8rem;
    line-height: 1.5rem;
    overflow: hidden;
    color: #774f4f;
`;

const PositionedLoading = styled(Loading)`
    position: absolute;
    top: -4px;
    left: -8px;
`;

const ContentBlock = ({
    label,
    value,
    subValue,
    isLoading,
    isDisabled,
    className
}: ContentBlockProps) => {
    const renderLoading = () => (
        <>&nbsp;<PositionedLoading width={35} lineWidth={2} /></>
    );

    return (
        <Container className={className} isDisabled={isDisabled ?? false}>
            <Label>{label}</Label>
            <Value>{isLoading ? renderLoading() : value}</Value>
            {!!subValue && !isLoading && <SubValue>{subValue}</SubValue>}
        </Container>
    )
}

export default ContentBlock;