import { ScreenSize } from "../hooks/useScreenSize";

const SCREEN_SIZES: ScreenSize[] = ['xs', 'sm', 'md', 'lg', 'xl', '2xl'];

const getIndex = (screenSize: ScreenSize): number => SCREEN_SIZES.indexOf(screenSize);

export const isSmallerTo = (screenSize: ScreenSize, compareTo: ScreenSize): boolean =>
    getIndex(screenSize) < getIndex(compareTo);

export const isSmallerOrEqualTo = (screenSize: ScreenSize, compareTo: ScreenSize): boolean =>
    getIndex(screenSize) <= getIndex(compareTo);

export const isLargerTo = (screenSize: ScreenSize, compareTo: ScreenSize): boolean =>
    getIndex(screenSize) > getIndex(compareTo);

export const isLargerOrEqualTo = (screenSize: ScreenSize, compareTo: ScreenSize): boolean =>
    getIndex(screenSize) >= getIndex(compareTo);
