import Network from '../Network';

export const IOTA_EVM_NETWORK_ID = 8822;
const IOTA_EVM_PUBLIC_RPC = 'https://json-rpc.evm.iotaledger.net/';

export const iota: Network = {
    id: IOTA_EVM_NETWORK_ID,
    chainIdentifier: 'iota',
    publicRpcUrl: IOTA_EVM_PUBLIC_RPC,
    name: 'IOTA',
    currency: 'IOTA',
    explorerUrl: 'https://explorer.evm.iota.org/',
    testnet: false,
};
