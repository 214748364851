import { BigNumber } from "ethers";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import useAppDispatch from "../../../hooks/useAppDispatch";
import { RootState } from "../../../store";
import deposit from "../redux/deposit";

export type UseDepositResult = {
    deposit: (amount: BigNumber) => void;
    isDepositing: boolean;
}

const useDeposit = (vaultId: string): UseDepositResult => {
    const dispatch = useAppDispatch()
    const { depositing } = useSelector((state: RootState) => state.vaults);
    const boundDeposit = useCallback((amount: BigNumber) => {
        dispatch(deposit(vaultId, amount))
    }, [dispatch, vaultId]);

    return {
        deposit: boundDeposit,
        isDepositing: depositing.includes(vaultId)
    }
}

export default useDeposit;