import { EMPTY_TIMEBUCKET } from "./shared";
import { Timebucket } from "../../../../services/databarn/IDatabarnService";
import { RootState } from "../../../../store";

export const selectUnderlyingToUsdTimebucketByVaultId = (
  state: RootState,
  vaultId: string,
  timebucket: Timebucket,
  walletAddress?: string
) => {
  walletAddress = walletAddress ?? state.wallet.address;
  if (!walletAddress) {
    return { ...EMPTY_TIMEBUCKET };
  }
  return (
    state.dashboard.byAddress[walletAddress.toLowerCase()]?.underlyingToUsd.byVaultId[vaultId]
      ?.byTimebucket[timebucket] || { ...EMPTY_TIMEBUCKET }
  );
};
