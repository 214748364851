import { VaultPnl } from "../../../redux/selectors";
import Vault from "../../../../../services/vaults/Vault";
import { yearlyToDaily } from "../../../../../utils/apyConversions";
import toFriendlyBigUsd from "../../../../../utils/toFriendlyBigUsd";
import { toFriendlyApy } from "../../../../../utils/toFriendlyApy";
import styled from "styled-components";
import VaultIcon, { VaultIconProps } from "../../../../vaults/views/ListVaults/components/VaultIcon";
import countTotalValue from "../../../../../utils/countTotalValue";
import { BalancesMapItem } from "../../../../vaults/redux/vaultsSlice";
import countDepositedBalance from "../../../../vaults/views/Vault/utils/countDepositedBalance";

const ICON_SIZE = 40

export type DashboardVaultItemRowProps = {
  balance?: BalancesMapItem;
  vault: Vault;
  yearlyApy: number;
  pnl: VaultPnl;
  isLast: boolean;
  onRowClick: (vault: Vault) => void;
}

const Row = styled.tr<{ isLast: boolean }>((({ isLast }) => ({
  borderBottom: isLast ? 'none' : '1px solid rgba(255,255,255,0.5)',
  transition: 'background-color 0.15s ease-in-out',
  '&:hover': {
    backgroundColor: '#67c0cb',
    cursor: 'pointer'
  }
})));

const Cell = styled.td`
  padding: .75rem 1rem;
  font-weight: bold;
  font-size: 1.05rem;
`;

const VaultCell = styled(Cell)`
  font-size: 1.25rem;
  font-weight: bold;
`;


type StyledVaultIconProps = VaultIconProps & {
  zIndex: number;
  marginLeft: number;
};


const StyledVaultIcon = styled(VaultIcon) <StyledVaultIconProps>`
  position: relative;
  margin-left: ${(props) => props.marginLeft}px;
  z-index: ${(props) => props.zIndex};
`;

function DashboardVaultItemRow({ isLast, vault, pnl, balance, yearlyApy, onRowClick }: DashboardVaultItemRowProps) {
  const dailyApy = yearlyToDaily(yearlyApy);

  const renderIcons = () => {
    const { assets } = vault;
    if (assets.length === 0) {
      return null;
    }

    return (
      <div>
        {assets.map((asset, index) => (
          <StyledVaultIcon
            asset={asset.id}
            key={index}
            size={ICON_SIZE}
            marginLeft={index === 0 ? 0 : -10}
            zIndex={vault.assets.length - index}
          />
        ))}
      </div>
    );
  };

  const resolveDepositUsd = () => {
    let usdValue = 0;
    if (pnl.hasTransactions) {
      usdValue = pnl.depositUsd.toNumber();
    } else if (balance) {
      // If there is no yet timeline information lets just use raw balance from the chain
      // and oracle price to determine the staked usd value
      const chainBalance = countDepositedBalance(balance, vault.token.decimals, vault.earn.decimals);
      usdValue = countTotalValue(chainBalance, vault.token.decimals, pnl.oraclePrice.toNumber());
    }
    return toFriendlyBigUsd(usdValue, 4);
  }

  return (
    <Row isLast={isLast} onClick={() => onRowClick(vault)}>
      <VaultCell>
        <div className="flex items-center gap-2">
          {renderIcons()}
          <div>{vault.name}</div>
        </div>
      </VaultCell>
      <Cell>{resolveDepositUsd()}</Cell>
      <Cell>{toFriendlyApy(yearlyApy, 2)}</Cell>
      <Cell>{toFriendlyApy(dailyApy, 2)}</Cell>
      <Cell>{toFriendlyBigUsd(pnl.totalYieldUsd.toNumber(), 4)}</Cell>
    </Row>
  )


}

export default DashboardVaultItemRow;