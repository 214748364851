import { useEffect } from "react";
import styled from "styled-components";
import useTotalTVL from "../../features/vaults/hooks/useTotalTVL";
import useVaults from "../../features/vaults/hooks/useVaults";
import toFriendlyBigUsd from "../../utils/toFriendlyBigUsd";
import Loading from "../Loading";

export type TotalTVLProps = {
    className?: string;
}

const Container = styled.div`
    background-color: rgba(0,0,0,0.4);
    border-radius: 1rem;
    padding: 1rem;
    text-align: center;
`;

const Label = styled.div`
    font-size: 1.5rem;
    font-style: italic;
    font-weight: 700;
`;

const Value = styled.div`
    font-size: 2.5rem;
    font-weight: 700;
`;

const TotalTVL: React.FC<TotalTVLProps> = ({
    className
}) => {

    const { vaults, fetchVaults } = useVaults();
    const { loading: loadingTvl, valueUSD: totalTvlUSD } = useTotalTVL(vaults);

    useEffect(() => {
        fetchVaults();
    }, [fetchVaults]);

    return (
        <Container className={className}>
            <Value>
                {loadingTvl ? <Loading width={42} lineWidth={4} /> : <>USD {toFriendlyBigUsd(totalTvlUSD)}</>}
            </Value>
            <Label>TOTAL VALUE LOCKED</Label>
        </Container>
    )
}

export default TotalTVL;