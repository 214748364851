import { BigNumber } from 'bignumber.js';
import { type BigNumberish, toBigNumber } from './bignumber';

/**
 * Formats: 123 -> $123, 1234 -> $1234, 1234567 -> $1.23M etc
 * @param input
 * @param decimals decimal places to display if formatted value <  decimalsUnder
 * @param minOrder order of magnitude to start showing units (1=k, 2=M, 3=B etc.)
 * @param decimalsUnder formatted value under which to show decimals
 */
export function formatLargeUsd(
  input: BigNumberish,
  decimals: number = 2,
  minOrder: number = 2,
  decimalsUnder: BigNumberish = 1000
): string {
  const value = toBigNumber(input);

  if (value.isZero()) {
    return '$0';
  }

  const prefix = value.isNegative() ? '-$' : '$';
  return `${prefix}${formatLargeNumber(value.absoluteValue(), decimals, minOrder, decimalsUnder)}`;
}

/**
 * Format a (BigNumber|number) to a shortened string for display
 * Attempts to only show {digits} overall digits (will show more if whole part is longer)
 * Condenses leading decimal zeros to subscript notation
 * @param input number or BigNumber
 * @param decimals how many decimals the token has
 * @param digits how many overall digits to display (default: 8)
 */
export function formatTokenDisplayCondensed(
  input: BigNumberish,
  decimals: number,
  digits: number = 8
): string {
  const value = toDecimalPlaces(input, decimals);

  if (value.isZero()) {
    return '0';
  }

  // Default/Clamp: all decimals
  if (digits === undefined || digits > decimals) {
    digits = decimals;
  }

  // Work out how many digits we have for whole and fraction
  const wholeDigits = value
    .absoluteValue()
    .decimalPlaces(0, BigNumber.ROUND_FLOOR)
    .toString(10).length;
  const decimalDigits = digits - wholeDigits;

  // Whole number only
  if (decimalDigits <= 0) {
    return formatGrouped(value, 0);
  }

  return condenseDecimalZeros(formatGrouped(value, decimals), decimalDigits);
}


/**
 * Defaults: 123 -> 123, 1234 -> 1,234, 1234567 -> 1.23M etc
 */
function formatLargeNumber(
  input: BigNumberish,
  decimals: number = 2,
  minOrder: number = 2,
  decimalsUnder: BigNumberish = 1000
): string {
  const inputValue = toBigNumber(input);

  if (inputValue.isZero()) {
    return '0';
  }

  const { value, unit } = toMagnitude(inputValue, minOrder);

  return `${formatGrouped(value, value.absoluteValue().lt(decimalsUnder) ? decimals : 0)}${unit}`;
}


function toDecimalPlaces(value: BigNumberish, decimals: number): BigNumber {
  return toBigNumber(value).decimalPlaces(decimals, BigNumber.ROUND_FLOOR);
}

function toMagnitude(value: BigNumber, minOrder: number = 1) {
  if (value.e === null) {
    return { value: value, unit: '' };
  }

  const order = Math.floor(value.e / 3);
  if (order < minOrder || order < 0) {
    return { value: value, unit: '' };
  }

  const units = ['', 'k', 'M', 'B', 'T', 'Q', 'S'];
  const newValue = value.shiftedBy(-order * 3);
  return { value: newValue, unit: units[order] };
}

function formatGrouped(value: BigNumber, decimals: number): string {
  return stripTrailingZeros(
    value.toFormat(decimals, BigNumber.ROUND_HALF_UP, {
      prefix: '',
      decimalSeparator: '.',
      groupSeparator: ',',
      groupSize: 3,
      secondaryGroupSize: 0,
      fractionGroupSeparator: '.',
      fractionGroupSize: 0,
      suffix: '',
    })
  );
}

function stripTrailingZeros(str: string) {
  return str.replace(/(\.[0-9]*?)(0+$)/, '$1').replace(/\.$/, '');
}

function condenseDecimalZeros(value: string, decimalDigits: number) {
  // Get whole and fraction part
  const [whole, decimal] = value.split('.');

  // No decimal part
  if (!decimal || !decimal.length) {
    return whole;
  }

  // Condense zeros
  let subLength = 0;
  const formattedDecimal = decimal.replace(/0*$/, '').replace(/^0{3,}/, match => {
    const removed = match.length.toString();
    const sub = toSubString(removed);
    subLength = sub.length;
    return `0${sub}`;
  });

  return `${whole}.${formattedDecimal.slice(0, decimalDigits + subLength).replace(/0*$/, '')}`;
}

function toSubString(input: string) {
  const subchars = '₀₁₂₃₄₅₆₇₈₉';
  return input.replace(/[0-9]/g, m => subchars[+m]);
}