import styled from "styled-components";
import Vault from "../../../../../services/vaults/Vault"
import { ApysMap, BalancesMap } from "../../../../vaults/redux/vaultsSlice";
import { VaultPnl } from "../../../redux/selectors";
import DashboardVaultItemRow from "./DashboardVaultItemRow";
import { useLocation, useNavigate } from "react-router";
import { toBigNumber } from "../../../../../utils/bignumber";

export type DashboardVaultListProps = {
  vaults: Vault[];
  apysMap: ApysMap;
  pnlMap: Record<string, VaultPnl>;
  balancesMap: BalancesMap;
}

const HeaderCell = styled.th`
  text-transform: uppercase;
  text-align: left;
  padding: 0 1rem;
  font-size: .8rem;
  font-weight: bold;
`;

const NoVaults = styled.div`
  text-align: center;
  font-style: italic;
  font-size: 1.5rem;
  color: white;
  padding: 2rem;
`;

function DashboardVaultList({ apysMap, balancesMap, pnlMap, vaults }: DashboardVaultListProps) {
  const navigate = useNavigate();
  const location = useLocation();

  if (vaults.length === 0) {
    return <NoVaults>No deposited vaults found.</NoVaults>
  }

  return (
    <table className="w-full">
      <thead>
        <tr>
          <HeaderCell />
          <HeaderCell>Deposited</HeaderCell>
          <HeaderCell>APY</HeaderCell>
          <HeaderCell>Daily</HeaderCell>
          <HeaderCell>Accrued yield</HeaderCell>
        </tr>
      </thead>
      <tbody>
        {vaults.map((vault, index) => (
          <DashboardVaultItemRow
            key={vault.id}
            onRowClick={(vault) => {
              navigate(`/vaults/${vault.id}`, { state: { from: location } });
            }}
            vault={vault}
            yearlyApy={apysMap[vault.id]}
            pnl={pnlMap[vault.id]}
            balance={balancesMap[vault.id] ?? null}
            isLast={vaults.length === index + 1}
          />
        ))}
      </tbody>
    </table>
  )

}

export default DashboardVaultList;