import { BigNumber } from "ethers";
import styled from "styled-components";
import Vault from "../../../../../../services/vaults/Vault";
import useWithdraw from "../../../../hooks/useWithdraw";
import { BalancesMapItem } from "../../../../redux/vaultsSlice";
import TransactInput from "./TransactInput";
import WithdrawableBalance from "./Balance";
import useWallet from "../../../../../wallet/hooks/useWallet";
import { useState } from "react";
import countDepositedBalance from "../../utils/countDepositedBalance";
import NotConnected from "./NotConnected";
import LoadingButton from "./LoadingButton";
import Slider from "../../../../../../components/Slider";
import WrongChain from "./WrongChain";

export type WithdrawProps = {
    vault: Vault;
    balances: BalancesMapItem
}

const WithdrawInfo = styled.p`
    font-size: .8rem;
    font-weight: 400;
`;

const WithdrawButton = styled(LoadingButton)``;

const Withdraw: React.FC<WithdrawProps> = ({
    vault,
    balances
}) => {
    const [inputValue, setInputValue] = useState<BigNumber | null>(null);
    const [sliderValue, setSliderValue] = useState<number>(0);
    const { isWithdrawing, withdraw, withdrawAll } = useWithdraw(vault.id);
    const { connected, networkId } = useWallet();
    const isConnectedToVaultsNetwork = networkId === vault.networkId;
    const balance = countDepositedBalance(balances, vault.token.decimals, vault.earn.decimals);

    const handleWithdrawClick = () => {
        if (BigNumber.isBigNumber(inputValue)) {
            if (sliderValue === 100) {
                withdrawAll()
            } else
                withdraw(inputValue);
        }
    }

    const withdrawButtonDisabled = (
        isWithdrawing ||
        !BigNumber.isBigNumber(inputValue) ||
        inputValue.gt(balance) ||
        inputValue.eq(0)
    );
    const withdrawButtonLoading = isWithdrawing;

    const transactInputMaxButtonValue = balance.gt(BigNumber.from(0)) ? balance : BigNumber.from(0);

    const handleInputChange = (bnValue: BigNumber | null) => {
        setInputValue(bnValue);
        if (bnValue) {
            setSliderValue(
                Number(bnValue.mul(100).div(balance).toString())
            );
        }
    }

    const handleSliderChange = (value: number) => {
        setSliderValue(value);
        setInputValue(
            BigNumber.from(balance).mul(value).div(100)
        );
    }

    const renderWithdrawButton = () => {
        if (!connected) {
            return <NotConnected className="mt-4" />;
        }
        if (!isConnectedToVaultsNetwork) {
            return <WrongChain className="mt-4" />
        }
        return (
            <div className="text-right">
                <WithdrawButton
                    className='mt-4'
                    onClick={handleWithdrawClick}
                    disabled={withdrawButtonDisabled}
                    loading={withdrawButtonLoading}
                >
                    Withdraw
                </WithdrawButton>
            </div>
        )
    }

    return (
        <div>
            <WithdrawableBalance
                className="mb-4"
                label="Withdrawable balance"
                walletBalance={BigNumber.from(balance)}
                tokenDecimals={vault.token.decimals}
                assets={vault.assets}
            />

            <TransactInput
                vaultAssets={vault.assets}
                onChange={handleInputChange}
                maxButtonValue={transactInputMaxButtonValue}
                decimals={vault.token.decimals}
                value={inputValue || BigNumber.from(0)}
            />

            <Slider className="mt-2" value={sliderValue} onChange={handleSliderChange} />

            {renderWithdrawButton()}

            <WithdrawInfo className="mt-4">

            </WithdrawInfo>
        </div>
    )
}

export default Withdraw;