import { useEffect, useState } from 'react';
import useVaults from "../../hooks/useVaults";
import ListTooling from './components/ListTooling/ListTooling';
import VaultItem from './components/VaultItem/VaultItem';
import useAppDispatch from '../../../../hooks/useAppDispatch';
import fetchPrices from '../../../prices/redux/fetchPrices';
import Vault from '../../../../services/vaults/Vault';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import styled from 'styled-components';

export type ListVaultsProps = {
    className?: string;
}

const ListContainer = styled.div`
    background-color: rgba(0,0,0,0.4);
    border-radius: 1rem;
`;

const NoVaults = styled.div`
    font-size: 1.25rem;
    color: white;
    text-align: center;
`;

const SquirrelContainer = styled.div`
    margin-top: -1.5rem;
`;

const SquirrelHello = styled.img``;

const ListVaults: React.FC<ListVaultsProps> = ({
    className
}) => {
    const dispatch = useAppDispatch();
    const { vaults: allVaults, fetchVaults } = useVaults();
    const { lps: lpPrices, tokens: tokenPrices } = useSelector((state: RootState) => state.prices);
    const { apysMap, balancesMap } = useSelector((state: RootState) => state.vaults);
    const [filteredVaults, setFilteredVaults] = useState<Vault[] | null>(null);
    const [sortedVaultIds, setSortedVaultIds] = useState<string[]>([]);

    useEffect(() => {
        fetchVaults();
    }, [fetchVaults]);

    useEffect(() => {
        dispatch(fetchPrices());
        setFilteredVaults((state) => state !== null ? state : allVaults);
    }, [allVaults, filteredVaults, dispatch]);

    const handleVaultsFiltered = (filteredVaultIds: string[]) => {
        const filteredVaults = allVaults.filter(v => filteredVaultIds.includes(v.id));
        setFilteredVaults(filteredVaults);
    }

    const renderVaults = () => {
        const filteredVaultsMap = (filteredVaults || []).reduce<{ [id: string]: Vault }>((res, vault) => {
            res[vault.id] = vault;
            return res;
        }, {});

        const vaults = sortedVaultIds.map(id => filteredVaultsMap[id] || null)
            .filter(Boolean);

        if (vaults.length === 0) {
            return <NoVaults className='col-span-full'>No results found... Try clearing your filters.</NoVaults>
        }

        return vaults.map(vault => <VaultItem key={vault.id} vault={vault} />);
    }

    return (
        <div className={className}>
            <ListContainer className='p-4 sm:p-8'>
                <div className='mb-20'>
                    <ListTooling
                        vaults={allVaults}
                        balancesMap={balancesMap}
                        apysMap={apysMap}
                        lpPrices={lpPrices}
                        tokenPrices={tokenPrices}
                        onVaultSorted={(ids) => setSortedVaultIds(ids)}
                        onVaultsFiltered={handleVaultsFiltered}
                    />
                </div>
                <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8'>
                    {renderVaults()}
                </div>
            </ListContainer>
            <SquirrelContainer className='flex justify-center'>
                <SquirrelHello src='/images/hello.png' />
            </SquirrelContainer>
        </div>
    );
}

export default ListVaults