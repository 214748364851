
const abbreviateNumber = (number: number, decimals: number): string => {
    const abbreviations: [number, string][] = [
        [1e3, 'K'],
        [1e6, 'M'],
        [1e9, 'B'],
        [1e12, 'T'],
        [1e15, 'Q'],
        [1e18, 'Qi'],
        [1e21, 'Sx'],
        [1e24, 'Sp'],
        [1e27, 'Oc'],
        [1e30, 'No']
    ];

    for (let i = abbreviations.length - 1; i >= 0; i--) {
        const [value, abbreviation] = abbreviations[i];
        if (number >= value) {
            const abbreviatedNumber = (number / value).toFixed(decimals);
            return `${abbreviatedNumber}${abbreviation}`;
        }
    }

    return number.toFixed(decimals);
}

const toFriendlyBigUsd = (value: number, decimalsUnder1 = 2): string => {
    if (value > 0 && value < 1) {
        return `$${value.toFixed(decimalsUnder1)}`;
    }

    return `$${abbreviateNumber(value, 2)}`;
}

export default toFriendlyBigUsd;