import { createAsyncThunk } from "@reduxjs/toolkit";
import { PricesFulfilled, PricesProps } from "./types";
import { RootState } from "../../../../store";
import getDatabarnService from "../../../../services/databarn/getDatabarnService";
import { DatabarnPriceType } from "../../../../services/databarn/IDatabarnService";

export const fetchShareToUnderlying = createAsyncThunk<
  PricesFulfilled,
  PricesProps,
  { state: RootState }
>(
  'analytics/fetchShareToUnderlying',
  async ({ productKey, walletAddress, timebucket, vaultId }, { getState }) => {
    const api = getDatabarnService();
    const data = await api.getPrice(productKey, DatabarnPriceType.ShareToUnderlying, timebucket);
    return {
      data,
      vaultId,
      timebucket,
      walletAddress: walletAddress.toLocaleLowerCase(),
      state: getState(),
    };
  }
);