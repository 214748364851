
const MESSAGES = [{
    found: 'internal json-rpc error',
    message: 'Internal JSON-RPC error.'
}, {
    found: 'user rejected transaction',
    message: 'User rejected transaction.'
}]

export const resolveFriendlyErrorMessage = (errorMessage: string): string => {
    for (const obj of MESSAGES) {
        if ((errorMessage || '').toLocaleLowerCase().includes(obj.found)) {
            return obj.message;
        }
    }
    return 'Unknown error.';
}