export const isNumeric = (x: unknown): x is number => typeof x === 'number' && !isNaN(x);

export const isFiniteNumber = (x: unknown): boolean => isNumeric(x) && isFinite(x);

export const compoundInterest = (
  rate: number,
  principal: number = 1,
  periods: number = 365,
  times: number = 1
): number => compound(rate, principal, periods, times) - principal;

export const compound = (
  rate: number,
  principal: number = 1,
  periods: number = 365,
  times: number = 1
): number => principal * Math.pow(1 + rate / periods, periods * times);