import styled from "styled-components";
import Network from "../networks/Network";
import { SHIMMER_NETWORK_ID } from "../networks/configurations/shimmer";
import { IOTA_EVM_NETWORK_ID } from "../networks/configurations/iota";

const Wrapper = styled.div<{ size: number, color: string }>`
  border-radius: 50%;
  padding: .25rem;
  height: ${props => `${props.size}px`};
  width: ${props => `${props.size}px`};
  background-color: ${props => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Icon = styled.img<{ size: number }>`
  height: ${props => `${props.size}px`};
`;

const ICON_MAP: { [networkId: number]: { url: string, backgroundColor: string, sizeRatio?: number } } = {
  [SHIMMER_NETWORK_ID]: {
    url: '/images/networks/shimmer.png',
    backgroundColor: '#131F37',
    sizeRatio: 0.75

  },
  [IOTA_EVM_NETWORK_ID]: {
    url: '/images/networks/iota.png',
    backgroundColor: '#fff'
  }
}

export type NetworkIconProps = {
  className?: string;
  network: Network;
  size?: number;
}

function NetworkIcon({ network, className, size = 40 }: NetworkIconProps) {
  const iconSettings = ICON_MAP[network.id];
  if (!iconSettings) {
    return null;
  }

  const iconSizeRatio = iconSettings.sizeRatio ?? 0.9;
  const iconSize = size * iconSizeRatio;

  return (
    <Wrapper className={className} size={size} color={iconSettings.backgroundColor}>
      <Icon size={iconSize} src={iconSettings.url} />
    </Wrapper>
  )
}

export default NetworkIcon;