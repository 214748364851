import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import useAppDispatch from "../../hooks/useAppDispatch";
import { RootState } from "../../store";
import fetchApys from "./redux/fetchApys";

export type VaultApysPollingContainerProps = {
    pollingIntervalMs: number;
}

const VaultApysPollingContainer: React.FC<VaultApysPollingContainerProps> = ({
    pollingIntervalMs
}) => {
    const dispatch = useAppDispatch();
    const { vaults } = useSelector((state: RootState) => state.vaults);

    const boundAction = useCallback(() => {
        dispatch(fetchApys(vaults.map(v => v.id)));
    }, [dispatch, vaults]);

    useEffect(() => {
        boundAction();

        const handle = setInterval(() => {
            boundAction();
        }, pollingIntervalMs);

        return () => {
            clearInterval(handle);
        }
    }, [boundAction, pollingIntervalMs]);

    return <></>
}

export default VaultApysPollingContainer;