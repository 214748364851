import { useMemo } from "react";
import { eachDayOfInterval } from "date-fns";
import useAppSelector from "../../../../../../hooks/useAppSelector";
import { selectLastVaultDepositStart } from "../../../../../dashboard/redux/selectors/selectLastVaultDepositStart";

const usePeriods = (vaultId: string, address?: string) => {
  const vaultDepositDate = useAppSelector(state =>
    selectLastVaultDepositStart(state, vaultId, address)
  );

  const result = eachDayOfInterval({
    start: vaultDepositDate,
    end: new Date(),
  });

  return useMemo(() => {
    if (result.length > 30) {
      return ['1D', '1W', '1M', 'ALL'];
    } else if (result.length > 7) {
      return ['1D', '1W', 'ALL'];
    } else if (result.length > 1) {
      return ['1D', 'ALL'];
    } else if (result.length === 1) {
      return ['1D']
    }
    return [];
  }, [result.length]);
};

export default usePeriods;
