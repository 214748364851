import { IOTA_EVM_NETWORK_ID } from '../../networks/configurations/iota';
import { SHIMMER_NETWORK_ID } from '../../networks/configurations/shimmer';
import { Asset, AssetIdentifier } from './Asset';
import { PlatformIdentifier } from './Platform';
import Vault, { RawVault, VaultOracle, VaultStrategy } from './Vault';
import { findAsset } from './assets';
import { findPlatform } from './platforms';

const vaults: RawVault[] = [
  {
    id: 'iota-iota-usdc',
    creationDate: '2024-07-28',
    bannerImage: '/images/vaults/MagicSea.webp',
    name: 'IOTA-USDC LP',
    assets: [AssetIdentifier.IOTA, AssetIdentifier.USDC],
    token: {
      tokenAddress: '0x8F9a72d8F56479903ae386849e290d49389e41F9',
      symbol: 'IOTA-USDC LP',
      decimals: 18,
      description: '',
    },
    earn: {
      tokenAddress: '0x8217093019Ac1c407d58f1E7E44bb7C27FF471dA',
      symbol: 'ACR-IOTA-USDC',
      decimals: 18,
    },
    networkId: IOTA_EVM_NETWORK_ID,
    host: PlatformIdentifier.MagicSea,
    strategy: VaultStrategy.LPStaking,
    oracle: {
      type: VaultOracle.Lp,
      id: 'iota-iota-usdc',
    },
  },
  {
    id: 'iota-iota-eth',
    creationDate: '2024-07-28',
    bannerImage: '/images/vaults/MagicSea.webp',
    name: 'ETH-IOTA LP',
    assets: [AssetIdentifier.ETH, AssetIdentifier.IOTA],
    token: {
      tokenAddress: '0xA4a8EF658aE0Dbcca558e0b2dbD9E51925180d32',
      symbol: 'ETH-IOTA LP',
      decimals: 18,
      description: '',
    },
    earn: {
      tokenAddress: '0x53059a5ad63fd5979700295246c5Ef0e644C970F',
      symbol: 'ACR-ETH-IOTA',
      decimals: 18,
    },
    networkId: IOTA_EVM_NETWORK_ID,
    host: PlatformIdentifier.MagicSea,
    strategy: VaultStrategy.LPStaking,
    oracle: {
      type: VaultOracle.Lp,
      id: 'iota-iota-eth',
    },
  },
  {
    id: 'iota-lum-iota',
    creationDate: '2024-07-28',
    bannerImage: '/images/vaults/MagicSea.webp',
    name: 'LUM-IOTA LP',
    assets: [AssetIdentifier.LUM, AssetIdentifier.IOTA],
    token: {
      tokenAddress: '0xa687eddac2648337492f37a182ca555e7e62b72a',
      symbol: 'LUM-IOTA LP',
      decimals: 18,
      description: '',
    },
    earn: {
      tokenAddress: '0x0A88d70644950811ddc8277d32a4b62C7e11EF83',
      symbol: 'ACR-LUM-IOTA',
      decimals: 18,
    },
    networkId: IOTA_EVM_NETWORK_ID,
    host: PlatformIdentifier.MagicSea,
    strategy: VaultStrategy.LPStaking,
    oracle: {
      type: VaultOracle.Lp,
      id: 'iota-lum-iota',
    },
  },
  {
    id: 'iota-iota-mlum',
    creationDate: '2024-07-28',
    bannerImage: '/images/vaults/MagicSea.webp',
    name: 'IOTA-MLUM LP',
    assets: [AssetIdentifier.IOTA, AssetIdentifier.MLUM],
    token: {
      tokenAddress: '0xB895f2Be2347c244f202CA4b86Db3a6722B10756',
      symbol: 'IOTA-MLUM LP',
      decimals: 18,
      description: '',
    },
    earn: {
      tokenAddress: '0x0661e50AAcaa235eDe7C1A167f4e679cE860Fe11',
      symbol: 'ACR-IOTA-MLUM',
      decimals: 18,
    },
    networkId: IOTA_EVM_NETWORK_ID,
    host: PlatformIdentifier.MagicSea,
    strategy: VaultStrategy.LPStaking,
    oracle: {
      type: VaultOracle.Lp,
      id: 'iota-iota-mlum',
    },
  },
  {
    id: 'shimmer-shimmer-lum',
    creationDate: '2023-08-10',
    bannerImage: '/images/vaults/MagicSea.webp',
    name: 'SMR-LUM LP',
    assets: [AssetIdentifier.shimmer, AssetIdentifier.LUM],
    token: {
      tokenAddress: '0x95f00a7125EC3D78d6B2FCD6FFd9989941eF25fC',
      symbol: 'SMR-LUM LP',
      decimals: 18,
      description: '',
    },
    earn: {
      tokenAddress: '0x11C0A85EAa5b8a703267231CA5C0e32038C0ffcf',
      symbol: 'ACR-SMR-LUM',
      decimals: 18,
    },
    networkId: SHIMMER_NETWORK_ID,
    host: PlatformIdentifier.MagicSea,
    strategy: VaultStrategy.LPStaking,
    oracle: {
      type: VaultOracle.Lp,
      id: 'shimmer-shimmer-lum',
    },
  },
  {
    id: 'shimmer-shimmer-deepr',
    creationDate: '2023-09-10',
    bannerImage: '/images/vaults/MagicSea.webp',
    name: 'DEEPR-SMR LP',
    assets: [AssetIdentifier.shimmer, AssetIdentifier.DEEPR],
    token: {
      tokenAddress: '0xD56C46DdE3079Bb7799826C6bff217665206100B',
      symbol: 'DEEPR-SMR LP',
      decimals: 18,
      description: '',
    },
    earn: {
      tokenAddress: '0xAC6BA03BDDA566a21CD87FeFc945820e7d718aFf',
      symbol: 'ACR-DEEPR-SMR',
      decimals: 18,
    },
    networkId: SHIMMER_NETWORK_ID,
    host: PlatformIdentifier.MagicSea,
    strategy: VaultStrategy.LPStaking,
    oracle: {
      type: VaultOracle.Lp,
      id: 'shimmer-shimmer-deepr',
    },
  },
  {
    id: 'shimmer-shimmer-apein',
    creationDate: '2023-10-10',
    bannerImage: '/images/vaults/MagicSea.webp',
    name: 'SMR-APEin LP',
    assets: [AssetIdentifier.shimmer, AssetIdentifier.APEin],
    token: {
      tokenAddress: '0xf187872D281C001552687479c8b92A0D45f0463F',
      symbol: 'SMR-APEin LP',
      decimals: 18,
      description: '',
    },
    earn: {
      tokenAddress: '0x0eD432E6055caB8f486950Ba656015c04B62B9FA',
      symbol: 'ACR-SMR-APEin LP',
      decimals: 18,
    },
    networkId: SHIMMER_NETWORK_ID,
    host: PlatformIdentifier.MagicSea,
    strategy: VaultStrategy.LPStaking,
    oracle: {
      type: VaultOracle.Lp,
      id: 'shimmer-shimmer-apein',
    },
  },
  {
    id: 'shimmer-shimmer-siota',
    creationDate: '2023-10-11',
    bannerImage: '/images/vaults/MagicSea.webp',
    name: 'SMR-sIOTA LP',
    assets: [AssetIdentifier.shimmer, AssetIdentifier.sIOTA],
    token: {
      tokenAddress: '0x59284c3EF3C4fEa5FECBDa5697Ed9C3f4BFA37F3',
      symbol: 'SMR-sIOTA LP',
      decimals: 18,
      description: '',
    },
    earn: {
      tokenAddress: '0x27Db3A9CfAeed2944E1B0871aB4816fA998B67cc',
      symbol: 'ACR-SMR-sIOTA LP',
      decimals: 18,
    },
    networkId: SHIMMER_NETWORK_ID,
    host: PlatformIdentifier.MagicSea,
    strategy: VaultStrategy.LPStaking,
    oracle: {
      type: VaultOracle.Lp,
      id: 'shimmer-shimmer-siota',
    },
  },
  {
    id: 'shimmer-shimmer-rust',
    creationDate: '2023-10-12',
    bannerImage: '/images/vaults/MagicSea.webp',
    name: 'RUST-SMR LP',
    assets: [AssetIdentifier.shimmer, AssetIdentifier.RUST],
    token: {
      tokenAddress: '0x9f43B71C94837f37700A5861d34c3bF8865cC728',
      symbol: 'RUST-SMR LP',
      decimals: 18,
      description: '',
    },
    earn: {
      tokenAddress: '0xCEFcf9a71B60146297A3D77b21839773074080BB',
      symbol: 'ACR-RUST-SMR LP',
      decimals: 18,
    },
    networkId: SHIMMER_NETWORK_ID,
    host: PlatformIdentifier.MagicSea,
    strategy: VaultStrategy.LPStaking,
    oracle: {
      type: VaultOracle.Lp,
      id: 'shimmer-shimmer-rust',
    },
  },
  {
    id: 'shimmer-shimmer-usdt',
    creationDate: '2023-10-12',
    bannerImage: '/images/vaults/MagicSea.webp',
    name: 'USDT-SMR LP',
    assets: [AssetIdentifier.shimmer, AssetIdentifier.USDT],
    token: {
      tokenAddress: '0xA3e6fBF50a05e6033eD48adC2351C6099b0B2499',
      symbol: 'USDT-SMR LP',
      decimals: 18,
      description: '',
    },
    earn: {
      tokenAddress: '0x6b41A98EA742EcBa994D08C0c850242A5daFac90',
      symbol: 'ACR-USDT-SMR LP',
      decimals: 18,
    },
    networkId: SHIMMER_NETWORK_ID,
    host: PlatformIdentifier.MagicSea,
    strategy: VaultStrategy.LPStaking,
    oracle: {
      type: VaultOracle.Lp,
      id: 'shimmer-shimmer-usdt',
    },
  },
  {
    id: 'shimmer-shimmer-eth',
    creationDate: '2023-10-12',
    bannerImage: '/images/vaults/MagicSea.webp',
    name: 'ETH-SMR LP',
    assets: [AssetIdentifier.shimmer, AssetIdentifier.ETH],
    token: {
      tokenAddress: '0x152D0436369aab0A83ca5F45B7C0dA3f26FC0Fa9',
      symbol: 'ETH-SMR LP',
      decimals: 18,
      description: '',
    },
    earn: {
      tokenAddress: '0x04D09a52e3D547C8f3e598dcCB3dFcC0CbB97CD7',
      symbol: 'ACR-ETH-SMR LP',
      decimals: 18,
    },
    networkId: SHIMMER_NETWORK_ID,
    host: PlatformIdentifier.MagicSea,
    strategy: VaultStrategy.LPStaking,
    oracle: {
      type: VaultOracle.Lp,
      id: 'shimmer-shimmer-eth',
    },
  },
  {
    id: 'shimmer-usdt-mlum',
    creationDate: '2023-10-12',
    bannerImage: '/images/vaults/MagicSea.webp',
    name: 'USDT-MLUM LP',
    assets: [AssetIdentifier.USDT, AssetIdentifier.MLUM],
    token: {
      tokenAddress: '0x9b6B4860657b08A611291aBE3133645E3A69CE72',
      symbol: 'USDT-MLUM LP',
      decimals: 18,
      description: '',
    },
    earn: {
      tokenAddress: '0x05C59236d4F0EEB315ed96ee7B411fe5A6F9De13',
      symbol: 'ACR-USDT-MLUM LP',
      decimals: 18,
    },
    networkId: SHIMMER_NETWORK_ID,
    host: PlatformIdentifier.MagicSea,
    strategy: VaultStrategy.LPStaking,
    oracle: {
      type: VaultOracle.Lp,
      id: 'shimmer-usdt-mlum',
    },
  },
  {
    id: 'shimmer-usdt-lum',
    creationDate: '2023-10-12',
    bannerImage: '/images/vaults/MagicSea.webp',
    name: 'USDT-LUM LP',
    assets: [AssetIdentifier.USDT, AssetIdentifier.LUM],
    token: {
      tokenAddress: '0x756bC5A3ed1Fe7B7bd20848d4038FE93E377987D',
      symbol: 'USDT-LUM LP',
      decimals: 18,
      description: '',
    },
    earn: {
      tokenAddress: '0x40059A5dCb4F47bCdd85784f3De00c0eB1E9D918',
      symbol: 'ACR-USDT-LUM LP',
      decimals: 18,
    },
    networkId: SHIMMER_NETWORK_ID,
    host: PlatformIdentifier.MagicSea,
    strategy: VaultStrategy.LPStaking,
    oracle: {
      type: VaultOracle.Lp,
      id: 'shimmer-usdt-lum',
    },
  },
  {
    id: 'shimmer-siota-rust',
    creationDate: '2023-11-12',
    bannerImage: '/images/vaults/MagicSea.webp',
    name: 'sIOTA-RUST LP',
    assets: [AssetIdentifier.sIOTA, AssetIdentifier.RUST],
    token: {
      tokenAddress: '0x66218f8303Ee8a2705B69944F8BFCF21dfcbd988',
      symbol: 'sIOTA-RUST LP',
      decimals: 18,
      description: '',
    },
    earn: {
      tokenAddress: '0x00c778d386A8F0117f960bbC149Ed042eaE4D01c',
      symbol: 'ACR-sIOTA-RUST LP',
      decimals: 18,
    },
    networkId: SHIMMER_NETWORK_ID,
    host: PlatformIdentifier.MagicSea,
    strategy: VaultStrategy.LPStaking,
    oracle: {
      type: VaultOracle.Lp,
      id: 'shimmer-siota-rust',
    },
  },

];

const populateVaults = (rawVaults: RawVault[]): Vault[] =>
  rawVaults.map((vault) => ({
    ...vault,
    host: findPlatform(vault.host),
    assets: vault.assets.map(findAsset).filter(Boolean) as Asset[],
  }));

export default populateVaults(vaults);
