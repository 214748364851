import { memo } from 'react';
import styled from 'styled-components';

const Container = styled.div({
  display: 'flex',
  columnGap: '24px',
  alignItems: 'center',
  padding: '0px 24px',
  justifyContent: 'space-between',
  ['@media only screen and (max-width: 768px)']: {
    padding: '0px 16px ',
  },
})

const ColorReference = styled.div({
  height: '2px',
  width: '12px',
})

const LegendItemRoot = styled.div({
  color: '#000',
  display: 'flex',
  columnGap: '8px',
  alignItems: 'center',
});

interface LegendItemProps {
  color: string;
  text: string;
}

const LegendItem = memo<LegendItemProps>(function LegendItem({ color, text }) {
  return (
    <LegendItemRoot>
      <ColorReference style={{ backgroundColor: color }} />
      <div>{text}</div>
    </LegendItemRoot>
  );
});


export interface LegendProps {
  vaultId: string;
}

export default function Legend() {
  return (
    <Container>
      <LegendItem
        color="#8F2D56"
        text='LP Amount'
      />
      <LegendItem color="#5C70D6" text='Deposit value (USD)' />
    </Container>
  );
}

