import styled from "styled-components";
import VaultIconComponent, { VaultIconProps } from "../../../ListVaults/components/VaultIcon";
import { Asset } from "../../../../../../services/vaults/Asset";

export type IconSetProps = {
    className?: string;
    assets: Asset[];
    size: number;
    borderWidth?: number;
}

const Icons = styled.div``;

const Icon = styled(VaultIconComponent) <VaultIconProps & { isOnly: boolean }>`
   margin-left: ${props => props.isOnly ? 0 : -10}px;
`;

const IconSet: React.FC<IconSetProps> = ({
    className,
    size,
    assets,
    borderWidth
}) => {
    return (
        <Icons className={className}>
            {assets.map(asset => (
                <Icon
                    key={asset.id}
                    isOnly={assets.length === 1}
                    asset={asset.id}
                    size={size}
                    borderWidth={borderWidth}
                />
            ))}
        </Icons>
    )
}

export default IconSet;