import { ethers } from "ethers";
import getNetwork from "../../networks/getNetwork";
import { WalletState } from "./redux/walletSlice";
import getPublicRpcProvider from "../../networks/getPublicRpcProvider";
import Network from "../../networks/Network";

const getProvider = (networkOrId: number | Network, wallet?: WalletState): ethers.providers.Provider => {
  const network = typeof networkOrId === 'number' ? getNetwork(networkOrId) : networkOrId;
  const userSignerProvider = wallet && network.id === wallet.networkId && wallet.signer?.provider
  if (userSignerProvider) {
    return userSignerProvider;
  }
  return getPublicRpcProvider(network);
}

export default getProvider;