import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import useAppDispatch from "../../../hooks/useAppDispatch";
import Vault, { VaultOracle } from "../../../services/vaults/Vault";
import { RootState } from "../../../store";
import countTotalValue from "../../../utils/countTotalValue";
import fetchPrices from "../../prices/redux/fetchPrices";
import fetchBalances from "../redux/fetchBalances";
import { BalancesMap } from "../redux/vaultsSlice";

const createTmpBalancesMap = (vaults: Vault[], fullBalancesMap: BalancesMap): BalancesMap => {
    const newMap: BalancesMap = {};
    vaults.forEach(vault => {
        newMap[vault.id] = fullBalancesMap[vault.id];
    });
    return newMap;
}

const allVaultsInitialized = (vaults: Vault[], balancesMap: BalancesMap): boolean => {
    const balanceKeys = Object.keys(balancesMap);
    const balanceKeyCount = balanceKeys.length;
    const equalLengths = vaults.length > 0 && vaults.length === balanceKeyCount;
    if (!equalLengths) {
        return false;
    }

    const initializedBalancesCount = balanceKeys
        .map(key => balancesMap[key] && balancesMap[key].initialized)
        .filter(Boolean)
        .length;

    return initializedBalancesCount === balanceKeyCount;
}

export type UseTotalTVLResult = {
    loading: boolean;
    valueUSD: number;
}

const useTotalTVL = (vaults: Vault[]) => {
    const dispatch = useAppDispatch();
    const [result, setResult] = useState<UseTotalTVLResult>({ loading: true, valueUSD: 0 });
    const { balancesMap } = useSelector((state: RootState) => state.vaults);
    const { initialized: pricesInitialized, lps, tokens } = useSelector((state: RootState) => state.prices);

    useEffect(() => {
        dispatch(fetchPrices());
    }, [dispatch]);

    useEffect(() => {
        if (!pricesInitialized) {
            return;
        }

        const vaultBalancesMap = createTmpBalancesMap(vaults, balancesMap);
        const isInitialized = allVaultsInitialized(vaults, vaultBalancesMap);
        if (isInitialized) {

            const countVaultTvl = (vault: Vault) => {
                const { oracle } = vault;
                return countTotalValue(
                    balancesMap[vault.id].totalBalance || '0',
                    vault.token.decimals,
                    oracle.type === VaultOracle.Lp ? lps[oracle.id] : tokens[oracle.id]
                );
            }

            let totalValueLockedUSD = 0;
            vaults.forEach(vault => {
                totalValueLockedUSD += countVaultTvl(vault);
            });

            setResult({ loading: false, valueUSD: totalValueLockedUSD });

        }
    }, [balancesMap, pricesInitialized, lps, tokens])

    return result;
}

export default useTotalTVL;