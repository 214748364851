import { BigNumber, Contract } from "ethers";
import Vault from "../../../services/vaults/Vault";
import { AppDispatch, GetAppState } from "../../../store";
import { WalletState } from "../../wallet/redux/walletSlice";
import abi from "../abis/vault";
import { BalancesMapItem, balancesUpdating, balancesUpdated } from "./vaultsSlice";
import getMulticallProvider, { MulticallProvider } from "../../wallet/getMulticallProvider";
import { uniq } from "lodash";

type BalanceItemValues = Omit<BalancesMapItem, 'initialized' | 'updating'>

type MulticallProviderMap = { [chainId: number]: MulticallProvider };

const fetchBalancesForVault = async (vault: Vault, wallet: WalletState, provider: MulticallProvider): Promise<BalanceItemValues> => {
    const tokenContract = new Contract(vault.token.tokenAddress, abi, provider);
    const earnContract = new Contract(vault.earn.tokenAddress, abi, provider);

    const promises: Array<Promise<BigNumber>> = [
        earnContract.balance(),
        earnContract.getPricePerFullShare()
    ];

    if (wallet.connected) {
        promises.push(
            tokenContract.balanceOf(wallet.address),
            tokenContract.allowance(wallet.address, vault.earn.tokenAddress),
            earnContract.balanceOf(wallet.address)
        );
    }

    try {
        const [totalBalance, pricePerFullShare, walletBalance, walletAllowance, stakedBalance] = await Promise.all(promises);

        return {
            totalBalance: totalBalance.toString(),
            pricePerFullShare: pricePerFullShare.toString(),
            walletBalance: (walletBalance) ? walletBalance.toString() : undefined,
            stakedBalance: (stakedBalance) ? stakedBalance.toString() : undefined,
            walletAllowance: (walletAllowance) ? walletAllowance.toString() : undefined
        }
    } catch (e) {
        console.error('Error on fetching balances', e);
        throw e;
    }
}

let globalFetchBalancesIndex = 0;

const fetchBalances = (vaultIds: string[], versionProtected = false) => {
    return async (dispatch: AppDispatch, getState: GetAppState) => {
        const { vaults: vaultsState, wallet } = getState();
        const { vaults } = vaultsState;
        const vaultsToBeFetched = vaults.filter(v => vaultIds.includes(v.id));
        const fetchIndex = ++globalFetchBalancesIndex;
        const networkIds = uniq(vaultsToBeFetched.map(vault => vault.networkId));
        const multicallProviderMap: MulticallProviderMap = {}
        networkIds.forEach(networkId => {
            multicallProviderMap[networkId] = getMulticallProvider(networkId, wallet);
        })

        dispatch(balancesUpdating(vaultsToBeFetched.map(v => v.id)));

        const results = await Promise.allSettled(vaultsToBeFetched.map(vault => fetchBalancesForVault(vault, wallet, multicallProviderMap[vault.networkId])));
        const resultMap: { [vaultId: string]: BalanceItemValues } = {}
        results.forEach((item, index) => {
            if (item.status === 'fulfilled') {
                resultMap[vaultsToBeFetched[index].id] = item.value;
            }
        });


        if (versionProtected && fetchIndex < globalFetchBalancesIndex) {
            return;
        }

        dispatch(balancesUpdated(resultMap));

    }
}

export default fetchBalances;