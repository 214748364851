import { memo, useMemo } from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import max from 'lodash/max';
import { formatUnderlyingTick, formatUsdTick, formatDateTimeTick, domainOffSet, getXInterval, mapRangeToTicks, TIME_BUCKET } from './utils';
import Legend from './Legend';
import XAxisTick from './XAxisTick';
import { selectVaultById } from '../../../../../redux/selectors/selectVaultById';
import useAppSelector from '../../../../../../../hooks/useAppSelector';
import { usePnLChartData } from '../usePnlGraphData';
import styled from 'styled-components';
import useScreenSize from '../../../../../../../hooks/useScreenSize';
import Loader from './Loader';
import PnLTooltip from './PnlTooltip';
import getNetwork from '../../../../../../../networks/getNetwork';

interface GraphProps {
  vaultId: string;
  period: number;
  address?: string;
}

const Container = styled.div({
  padding: '16px 0px',
  '& text': {
    fill: '#222',
    '&.recharts-cartesian-axis-tick-value': {
      textTransform: 'initial',
    },
  },
});

const StyledLineChart = styled(LineChart)({
  '& .recharts-yAxis': {
    '& .recharts-cartesian-axis-tick': {
      opacity: 1,
      transition: 'ease-in-out 0.5s',
    },
  },
  '&:hover': {
    '& .recharts-yAxis': {
      '& .recharts-cartesian-axis-tick': {
        opacity: 0.5,
        transition: 'ease-in-out 0.5s',
      },
    },
  },
});

export const Graph = memo<GraphProps>(function Graph({ vaultId, period, address }) {
  const vault = useAppSelector(state => selectVaultById(state, vaultId));
  const vaultNetwork = getNetwork(vault.networkId);

  const productKey = useMemo(() => {
    return `beefy:vault:${vaultNetwork.chainIdentifier}:${vault.earn.tokenAddress.toLowerCase()}`;
  }, [vault.networkId, vault.earn.tokenAddress]);

  const { chartData, isLoading } = usePnLChartData(
    TIME_BUCKET[period],
    productKey,
    vaultId,
    address
  );

  const { data, minUnderlying, maxUnderlying, minUsd, maxUsd } = chartData;

  const underlyingDiff = useMemo(() => {
    return domainOffSet(minUnderlying, maxUnderlying, 0.88);
  }, [maxUnderlying, minUnderlying]);

  const usdDiff = useMemo(() => {
    return domainOffSet(minUsd, maxUsd, 0.88);
  }, [maxUsd, minUsd]);

  const startUnderlyingDomain = useMemo(() => {
    return max([0, minUnderlying - underlyingDiff])!;
  }, [minUnderlying, underlyingDiff]);

  const startUsdDomain = useMemo(() => {
    return max([0, minUsd - usdDiff])!;
  }, [minUsd, usdDiff]);

  const underlyingAxisDomain = useMemo<[number, number]>(() => {
    return [startUnderlyingDomain, maxUnderlying + underlyingDiff];
  }, [maxUnderlying, startUnderlyingDomain, underlyingDiff]);

  const usdAxisDomain = useMemo<[number, number]>(() => {
    return [startUsdDomain, maxUsd + usdDiff];
  }, [maxUsd, startUsdDomain, usdDiff]);

  const underlyingTicks = useMemo(() => {
    return mapRangeToTicks(startUnderlyingDomain, maxUnderlying + underlyingDiff);
  }, [maxUnderlying, startUnderlyingDomain, underlyingDiff]);

  const usdTicks = useMemo(() => {
    return mapRangeToTicks(startUsdDomain, maxUsd + usdDiff);
  }, [maxUsd, startUsdDomain, usdDiff]);

  const underlyingTickFormatter = useMemo(() => {
    return (value: number) => formatUnderlyingTick(value, underlyingAxisDomain);
  }, [underlyingAxisDomain]);

  const dateTimeTickFormatter = useMemo(() => {
    return (value: number) => formatDateTimeTick(value, TIME_BUCKET[period]);
  }, [period]);

  const xsDown = useScreenSize() === 'xs';

  const xInterval = useMemo(() => {
    return getXInterval(data.length, xsDown);
  }, [data.length, xsDown]);

  const xMargin = useMemo(() => {
    return xsDown ? 16 : 24;
  }, [xsDown]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Legend />
      <ResponsiveContainer width="100%" height={200}>
        <StyledLineChart
          width={450}
          height={200}
          data={data}
          margin={{ top: 14, right: xMargin, bottom: 0, left: xMargin }}
        >
          <CartesianGrid strokeDasharray="2 2" stroke="#5f6485" />
          <XAxis
            tickFormatter={dateTimeTickFormatter}
            dataKey="datetime"
            padding="no-gap"
            tickMargin={10}
            stroke="#363B63"
            interval={xInterval}
            tick={XAxisTick}
          />
          <Line
            yAxisId="underliying"
            strokeWidth={1.5}
            dataKey="underlyingBalance"
            stroke="#8F2D56"
            dot={false}
            type="linear"
          />
          <Line
            yAxisId="usd"
            strokeWidth={1.5}
            dataKey="usdBalance"
            stroke="#5C70D6"
            dot={false}
            type="linear"
          />
          <YAxis
            stroke="#8F2D56"
            strokeWidth={1.5}
            tickFormatter={underlyingTickFormatter}
            yAxisId="underliying"
            domain={underlyingAxisDomain}
            ticks={underlyingTicks}
            mirror={xsDown}
          />
          <YAxis
            stroke="#5C70D6"
            orientation="right"
            strokeWidth={1.5}
            tickFormatter={formatUsdTick}
            yAxisId="usd"
            domain={usdAxisDomain}
            ticks={usdTicks}
            mirror={xsDown}
          />
          <Tooltip wrapperStyle={{ outline: 'none' }} content={<PnLTooltip />} />
        </StyledLineChart>
      </ResponsiveContainer>
    </Container>
  );
});

export default Graph;