import classNames from "classnames";
import styled from "styled-components";

export type Tab = {
    id: string;
    label: string;
}

export type TabsProps = {
    className?: string;
    tabs: Tab[];
    selectedTabId: string;
    onTabChanged: (tabId: string) => void;
}

const Container = styled.div`

`;

type TabButtonSide = 'right' | 'center' | 'left';

const TabButton = styled.button<{ active: boolean, side: TabButtonSide }>`
    font-size: .75rem;
    font-weight: 600;
    text-transform: uppercase;
    width: 50%;
    background-color: ${props => props.active ? '#73D2DE' : '#8F2D56'};
    padding: .75rem 0;
    ${(props) => props.side === 'left' && 'border-top-left-radius: .5rem;'}
    ${(props) => props.side === 'right' && 'border-top-right-radius: .5rem;'}
`;

const Tabs: React.FC<TabsProps> = ({
    className,
    tabs,
    onTabChanged,
    selectedTabId
}) => {

    const containerClasses = classNames('flex', 'justify-center', className);

    return (
        <Container className={containerClasses}>
            {tabs.map((tab, index) => {
                const handleClick = () => {
                    onTabChanged(tab.id);
                }

                let side: TabButtonSide = 'center';
                if (index === 0) {
                    side = 'left';
                } else if (index === tabs.length - 1) {
                    side = 'right';
                }

                return (
                    <TabButton
                        side={side}
                        active={selectedTabId === tab.id}
                        onClick={handleClick}
                        key={tab.id}
                    >
                        {tab.label}
                    </TabButton>
                );
            })}
        </Container>
    );
}

export default Tabs;