import BigNumber from 'bignumber.js';
import { format } from 'date-fns';
import { formatLargeUsd, formatTokenDisplayCondensed } from '../../../../../../../utils/format';
import styled from 'styled-components';

const Container = styled.div({
  color: '#000',
  padding: '12px 16px',
  minWidth: '250px',
  background: '#fff',
  borderRadius: '8px',
  textAlign: 'left',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
});

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 4px;
`;

const Value = styled.div`
  fontWeight: 600;
`;

type PayloadData = {
  datetime: string;
  underlyingBalance: string;
  usdBalance: string;
};

interface TooltipProps {
  active?: boolean;
  payload?: { payload: PayloadData }[];
}

export default function PnLTooltip({ active, payload }: TooltipProps) {
  if (active && payload && payload.length) {
    const formattedDate = format(new Date(payload[0].payload.datetime), 'MMM d, yyyy h:mm a');
    const shares = new BigNumber(payload[0].payload.underlyingBalance);
    const usdBalance = new BigNumber(payload[0].payload.usdBalance);

    return (
      <Container>
        <div>{formattedDate}</div>
        <Item>
          <div>Your Deposit:</div>
          <Value>{formatTokenDisplayCondensed(shares, 18)}</Value>
        </Item>
        <Item>
          <div>Deposit Value (USD):</div>
          <Value>{formatLargeUsd(usdBalance)}</Value>
        </Item>
      </Container>
    );
  }

  return null;
};
