import styled from "styled-components";
import Network from "../../../../../../networks/Network"
import classNames from "classnames";
import NetworkIcon from "../../../../../../components/NetworkIcon";

export type NetworkFiltersProps = {
  className?: string;
  networks: Network[];
  selectedNetworkIds: number[];
  onNetworkClick: (network: Network) => void;
}

const NetworkButton = styled.button<{ active: boolean }>`
  padding: .25rem;
  background-color: #561317;
  border-radius: .25rem;
  border: 2px solid #73D2DE;
  ${props => props.active ? '' : 'opacity: 0.35;'} 
`;

function NetworkFilters({ className, networks, selectedNetworkIds, onNetworkClick }: NetworkFiltersProps) {
  const renderNetwork = (network: Network) => {
    const isActive = selectedNetworkIds.length === 0 || selectedNetworkIds.includes(network.id);
    return (
      <NetworkButton active={isActive} key={network.id} onClick={() => onNetworkClick(network)}>
        <NetworkIcon size={40} network={network} />
      </NetworkButton>
    )
  }

  return (
    <div className={classNames("flex gap-2 justify-center", className)}>
      {networks.map(renderNetwork)}
    </div>
  )
}

export default NetworkFilters;