import { useEffect, useMemo } from 'react';
import { isAfter } from 'date-fns';
import minBy from 'lodash/minBy';
import maxBy from "lodash/maxBy";

import useAppSelector from '../../../../../../hooks/useAppSelector';
import useAppDispatch from '../../../../../../hooks/useAppDispatch';
import Vault from '../../../../../../services/vaults/Vault';
import { selectPriceForVault } from '../../../../../prices/redux/selectors';
import useBalance from '../../../../hooks/useBalance';
import { BIGNUMBER_ZERO, toBigNumber } from '../../../../../../utils/bignumber';
import { getInvestorTimeserie } from '../../../../../../utils/timeserie';
import { Timebucket } from '../../../../../../services/databarn/IDatabarnService';
import { selectLastVaultDepositStart } from '../../../../../dashboard/redux/selectors/selectLastVaultDepositStart';
import { selectTimelineByVaultId } from '../../../../../dashboard/redux/selectors/selectTimelineByVaultId';
import { fetchShareToUnderlying } from '../../../../../dashboard/redux/actions/fetchShareToUnderlying';
import { fetchUnderlyingToUsd } from '../../../../../dashboard/redux/actions/fetchUnderlyingToUsd';
import { selectShareToUnderlyingTimebucketByVaultId } from '../../../../../dashboard/redux/selectors/selectShareToUnderlyingTimebucketByVaultId';
import { selectUnderlyingToUsdTimebucketByVaultId } from '../../../../../dashboard/redux/selectors/selectUnderlyingToUsdTimebucketByVaultId';

const FETCH_DATA_RETRY_WAIT_MS = 5000;

// Same object reference for empty chart data
export const NO_CHART_DATA = { data: [], minUnderlying: 0, maxUnderlying: 0, minUsd: 0, maxUsd: 0 };

export const usePnLChartData = (
  timebucket: Timebucket,
  productKey: string,
  vaultId: string,
  address?: string
) => {
  const dispatch = useAppDispatch();
  const walletAddress = useAppSelector(state => address ?? state.wallet.address);
  const vaultTimeline = useAppSelector(state =>
    selectTimelineByVaultId(state, vaultId, walletAddress)
  );
  const vault = useAppSelector(state => state.vaults.vaults.find(vault => vault.id === vaultId) as Vault)
  const { balances } = useBalance(vault.id);
  const currentPpfs = toBigNumber(balances.pricePerFullShare).shiftedBy(-vault.token.decimals);
  const currentOraclePrice = useAppSelector(state =>
    selectPriceForVault(state, vault.id, vault.oracle.type)
  );

  const currentTokenBalance = toBigNumber(balances.stakedBalance ?? BIGNUMBER_ZERO).shiftedBy(-vault.token.decimals);

  const vaultLastDeposit = useAppSelector(state =>
    selectLastVaultDepositStart(state, vaultId, walletAddress)
  );

  const { data: sharesToUnderlying, status: sharesStatus } = useAppSelector(state =>
    selectShareToUnderlyingTimebucketByVaultId(state, vaultId, timebucket, walletAddress)
  );

  const { data: underlyingToUsd, status: underlyingStatus } = useAppSelector(state =>
    selectUnderlyingToUsdTimebucketByVaultId(state, vaultId, timebucket, walletAddress)
  );

  useEffect(() => {
    if (walletAddress) {
      if (sharesStatus === 'idle') {
        dispatch(
          fetchShareToUnderlying({
            productKey,
            vaultId,
            walletAddress,
            timebucket,
          })
        );
      }
      if (underlyingStatus === 'idle') {
        dispatch(
          fetchUnderlyingToUsd({
            productKey,
            vaultId,
            walletAddress,
            timebucket,
          })
        );
      }

      if (sharesStatus === 'rejected') {
        const handleShareToUnderlying = setTimeout(
          () =>
            dispatch(
              fetchShareToUnderlying({
                productKey,
                vaultId,
                walletAddress,
                timebucket,
              })
            ),
          FETCH_DATA_RETRY_WAIT_MS
        );
        return () => clearTimeout(handleShareToUnderlying);
      }

      if (underlyingStatus === 'rejected') {
        const handleUnderlyingToUsd = setTimeout(
          () =>
            dispatch(
              fetchUnderlyingToUsd({
                productKey,
                vaultId,
                walletAddress,
                timebucket,
              })
            ),
          FETCH_DATA_RETRY_WAIT_MS
        );
        return () => clearTimeout(handleUnderlyingToUsd);
      }
    }
  }, [dispatch, sharesStatus, underlyingStatus, timebucket, productKey, vaultId, walletAddress]);

  const isLoading = useMemo(
    () => underlyingStatus !== 'fulfilled' || sharesStatus !== 'fulfilled',
    [sharesStatus, underlyingStatus]
  );

  const chartData = useMemo(() => {
    if (sharesStatus === 'fulfilled' && underlyingStatus === 'fulfilled') {
      const filteredSharesToUnderlying = sharesToUnderlying.filter(price =>
        isAfter(price.datetime, vaultLastDeposit)
      );
      const filteredUnderlyingToUsd = underlyingToUsd.filter(price =>
        isAfter(price.datetime, vaultLastDeposit)
      );

      const data = getInvestorTimeserie(
        timebucket,
        vaultTimeline,
        filteredSharesToUnderlying,
        filteredUnderlyingToUsd,
        vaultLastDeposit,
        currentPpfs,
        currentOraclePrice,
        currentTokenBalance
      );

      if (data && data.length > 0) {
        const minUsd = minBy(data, row => row.usdBalance)?.usdBalance || 0;
        const maxUsd = maxBy(data, row => row.usdBalance)?.usdBalance || 0;

        const minUnderlying = minBy(data, row => row.underlyingBalance)?.underlyingBalance || 0;
        const maxUnderlying = maxBy(data, row => row.underlyingBalance)?.underlyingBalance || 0;

        return { data, minUnderlying, maxUnderlying, minUsd, maxUsd };
      }
    }

    // This save us from re-rendering when data is loading
    // We need to make sure this object is not modified elsewhere
    return NO_CHART_DATA;
  }, [
    sharesToUnderlying,
    underlyingToUsd,
    currentTokenBalance,
    currentOraclePrice,
    currentPpfs,
    vaultLastDeposit,
    vaultTimeline,
    sharesStatus,
    underlyingStatus,
    timebucket,
  ]);

  return { chartData, isLoading };
};

