import { BucketData } from "../dashboardSlice";
import { EMPTY_TIMEBUCKET } from "./shared";
import { Timebucket } from "../../../../services/databarn/IDatabarnService";
import { RootState } from "../../../../store";

export const selectShareToUnderlyingTimebucketByVaultId = (
  state: RootState,
  vaultId: string,
  timebucket: Timebucket,
  walletAddress?: string
): BucketData => {
  walletAddress = walletAddress ?? state.wallet.address
  if (!walletAddress) {
    return { ...EMPTY_TIMEBUCKET };
  }

  const addressKey = walletAddress.toLowerCase();
  const addressState = state.dashboard.byAddress[addressKey];

  if (!addressState) {
    return { ...EMPTY_TIMEBUCKET };
  }

  const vaultState = addressState.shareToUnderlying.byVaultId[vaultId];
  if (!vaultState) {
    return { ...EMPTY_TIMEBUCKET };
  }

  const bucketState = vaultState.byTimebucket[timebucket];
  if (!bucketState) {
    return { ...EMPTY_TIMEBUCKET };
  }

  return bucketState;
};
