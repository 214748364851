import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ethers } from "ethers";

export type WalletState = {
    connected: boolean;
    connecting: boolean;
    disconnecting: boolean;
    address: string;
    networkId: number;
    signer: ethers.Signer;
}

const initialState: WalletState = {
    connected: false,
    connecting: false,
    disconnecting: false,
    address: '',
    networkId: 0,
    signer: null as any,
}

export type UserConnectedPayload = {
    address: string;
    networkId: number;
    signer: ethers.Signer;
}

const walletSlice = createSlice({
    name: 'wallet',
    initialState,
    reducers: {
        walletConnecting: (state) => ({
            ...state,
            connecting: true
        }),
        walletDisconnecting: (state) => ({
            ...state,
            disconnecting: true
        }),
        walletConnected: (state, action: PayloadAction<UserConnectedPayload>) => ({
            ...state,
            connected: true,
            connecting: false,
            address: action.payload.address,
            networkId: action.payload.networkId,
            signer: action.payload.signer,
        }),
        walletNetworkChanged: (state, action: PayloadAction<number>) => ({
            ...state,
            networkId: action.payload
        }),
        walletAccountChanged: (state, action: PayloadAction<string>) => ({
            ...state,
            address: action.payload
        }),
        walletDisconnected: () => ({
            ...initialState
        })
    }
});

export const {
    walletConnected,
    walletConnecting,
    walletAccountChanged,
    walletNetworkChanged,
    walletDisconnecting,
    walletDisconnected
} = walletSlice.actions

export default walletSlice.reducer;