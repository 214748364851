import { BigNumber, utils } from "ethers";
import styled from "styled-components";
import IconSet from "./Icons";
import { Asset } from "../../../../../../services/vaults/Asset";

export type BalanceProps = {
    className?: string;
    label: string;
    walletBalance: BigNumber;
    tokenDecimals: number;
    assets: Asset[];
}

const Container = styled.div`
    text-align: right;
`;

const Label = styled.div`
    text-transform: uppercase;
    font-weight: 200;
    font-size: .8rem;
`;

const BalanceInfo = styled.div`
    font-weight: 600;
    line-height: 1rem;
    font-size: 1rem;
`;

const Balance: React.FC<BalanceProps> = ({
    className,
    label,
    walletBalance,
    tokenDecimals,
    assets
}) => {

    let balanceStr = utils.formatUnits(walletBalance, tokenDecimals);
    let [wholePart, decimalPart = ''] = balanceStr.split('.');
    if (decimalPart.length > 13) {
        decimalPart = decimalPart.substring(0, 13);
        balanceStr = [wholePart, decimalPart].join('.');
    }

    return (
        <Container className={className}>
            <Label>{label}</Label>
            <BalanceInfo className='flex items-center justify-end'>
                <div>{balanceStr}</div>
                <IconSet assets={assets} size={20} borderWidth={2} className='ml-4' />
            </BalanceInfo>
        </Container>
    );
}

export default Balance;