import { Asset, AssetIdentifier } from "./Asset";
import { Platform, PlatformIdentifier } from "./Platform";

export enum VaultOracle {
    Token,
    Lp
}

export enum VaultStrategy {
    SingleStaking,
    StableStaking,
    LPStaking
}

export type RawVault = {
    id: string;
    creationDate: string;
    assets: AssetIdentifier[];
    bannerImage?: string;
    networkId: number;
    name: string;
    strategy: VaultStrategy;
    host: PlatformIdentifier;
    oracle: {
        type: VaultOracle,
        id: string;
    },
    token: {
        tokenAddress: string;
        symbol: string;
        decimals: number;
        description?: string;
    },
    earn: {
        tokenAddress: string;
        symbol: string;
        decimals: number;
    }
}

type Vault = Omit<RawVault, 'assets' | 'host'> & {
    assets: Asset[];
    host: Platform;
}

export default Vault;