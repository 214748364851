import getVaultsService from "../../../services/vaults/getVaultsService";
import { AppDispatch } from "../../../store";
import fetchApys from "./fetchApys";
import { vaultsAdded, initialized, vaultsLoading } from "./vaultsSlice";

const fetchVaults = () => {
    return async (dispatch: AppDispatch) => {
        dispatch(vaultsLoading());
        const vaultsService = getVaultsService();
        const vaults = await vaultsService.listVaults();
        const vaultIds = vaults.map(vault => vault.id);
        dispatch(vaultsAdded(vaults));
        await dispatch(fetchApys(vaultIds));
        dispatch(initialized());
    }
}

export default fetchVaults;