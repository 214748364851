import { ethers } from "ethers";
import getNetwork from "../../networks/getNetwork";
import { WalletState } from "./redux/walletSlice";
import getPublicRpcProvider from "../../networks/getPublicRpcProvider";
import Network from "../../networks/Network";

const getSignerOrProvider = (networkOrId: number | Network, wallet?: WalletState): ethers.Signer | ethers.providers.Provider => {
    const network = typeof networkOrId === 'number' ? getNetwork(networkOrId) : networkOrId;
    const userSigner = wallet && network.id === wallet.networkId && wallet.signer;
    if (userSigner) {
        return userSigner
    }
    return getPublicRpcProvider(network);
}

export default getSignerOrProvider;