import styled from 'styled-components';
import Loading from '../../../../../../../components/Loading';

const Root = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '16px 0px',
})

export type LoaderProps = {
  size?: number;
}

export default function Loader({ size = 125 }: LoaderProps) {
  return (
    <Root>
      <Loading width={size} color="#8F2D56" />
    </Root>
  );
}
