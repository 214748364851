import { Route, Routes } from 'react-router';
import styled from 'styled-components';
import PricesPollingContainer from './features/prices/PricesPollingContainer';
import VaultApysPollingContainer from './features/vaults/VaultApysPollingContainer';
import Header from './components/Header/Header';
import ListVaults from './features/vaults/views/ListVaults/ListVaults';
import Vault from './features/vaults/views/Vault/VaultView';
import VaultBalancesPollingContainer from './features/vaults/VaultBalancesPollingContainer';
import Footer from './components/Footer';
import NotificationContainer from './features/notifications/NotificationContainer';
import Dashboard from './features/dashboard/views/Dashboard/Dashboard';

const Layout = styled.div`
  min-height: 100vh;
`;

const Container = styled.div.attrs({ className: 'mx-auto px-4 mb-12' })`
  max-width: 1280px;
  width: 100%;
`;

const FooterContainer = styled.div``;

const BALANCES_POLLING_INTERVAL_MS = 120 * 1000;
const PRICES_POLLING_INTERVAL_MS = 60 * 1000;
const APYS_POLLING_INTERVAL_MS = 60 * 1000;

const App: React.FC = () => {
  return (
    <>
      <Layout className="flex flex-col justify-between">
        <Container>
          <Header className="mt-4 mb-12" />

          <Routes>
            <Route path="/" element={<ListVaults />} />
            <Route path="/vaults/:vaultId" element={<Vault />} />
            <Route path="/dashboard" element={<Dashboard />} />
          </Routes>
        </Container>

        <FooterContainer>
          <Footer />
        </FooterContainer>
      </Layout>

      <PricesPollingContainer pollingIntervalMs={PRICES_POLLING_INTERVAL_MS} />
      <VaultApysPollingContainer pollingIntervalMs={APYS_POLLING_INTERVAL_MS} />
      <VaultBalancesPollingContainer
        pollingIntervalMs={BALANCES_POLLING_INTERVAL_MS}
      />

      <NotificationContainer />
    </>
  );
};

export default App;
