import { memo, useState } from 'react';
import Graph from './components/Graph';
import { selectHasDataToShowGraphByVaultId } from '../../../../../dashboard/redux/selectors/selectHasDataToShowInGraphByVaultId';
import useAppSelector from '../../../../../../hooks/useAppSelector';
import usePeriods from './usePeriods';

interface PnLGraphProps {
  vaultId: string;
  address?: string | undefined;
}

const PnLGraph = memo<PnLGraphProps>(function PnLGraph({ vaultId, address }) {
  const hasData = useAppSelector(state =>
    selectHasDataToShowGraphByVaultId(state, vaultId, address)
  );

  const labels = usePeriods(vaultId, address);

  if (!hasData) {
    return null;
  }

  return (
    <Graph address={address} period={labels.length - 1} vaultId={vaultId} />
  );
});

export default PnLGraph;