import { BigNumber } from "ethers";
import bigNumberishToFixedNumber from "../../../../../utils/bigNumberishToFixedNumber";
import { BalancesMapItem } from "../../../redux/vaultsSlice";

const countDepositedBalance = (balanceItem: BalancesMapItem, tokenDecimals: number, earnDecimals: number): BigNumber => {
    const fixedPricePerShare = bigNumberishToFixedNumber(balanceItem.pricePerFullShare || '0', earnDecimals);
    const fixedStakedBalance = bigNumberishToFixedNumber(balanceItem.stakedBalance || '0', tokenDecimals);
    return BigNumber.from(fixedPricePerShare.mulUnsafe(fixedStakedBalance));
}

export default countDepositedBalance; 