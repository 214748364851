import { createWeb3Modal as createWeb3ModalOriginal, defaultConfig } from '@web3modal/ethers5/react';
import getNetworks from '../../networks/getNetworks';

export type Web3Modal = ReturnType<typeof createWeb3ModalOriginal>;

const RABBY_WALLET_ID = '18388be9ac2d02726dbac9777c96efaac06d744b2f6d580fccdd4127a6d01fd1';
const BLOOM_WALLET_ID = '12ffbeb52b37f08bd8224a50e1cd2173286bf432832579861a5480bd662e349d';

export const createWeb3Modal = (): Web3Modal => {
    const modal = createWeb3ModalOriginal({
        featuredWalletIds: [
            RABBY_WALLET_ID,
            BLOOM_WALLET_ID
        ],
        ethersConfig: defaultConfig({
            metadata: {
                name: 'Accumulator',
                description: 'We auto-compound your LP tokens to generate even more LP tokens!',
                url: process.env.REACT_APP_METADATA_APPLICATION_URL ?? 'https://app.accumulator.finance/',
                icons: []
            }
        }),
        chains: getNetworks().map((network) => ({
            chainId: network.id,
            name: network.name,
            currency: network.currency,
            explorerUrl: network.explorerUrl,
            rpcUrl: network.publicRpcUrl
        })),
        projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID ?? '7d6ece665e9e27559c837ccd3a732676'
    });

    return modal;
};