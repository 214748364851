
import { RootState } from "../../../../store";
import { selectVaultPnls } from "./selectVaultPnls";
import { BIGNUMBER_ZERO } from "../../../../utils/bignumber";

export const selectTotalYieldUsd = (state: RootState, walletAddress: string) => {
  const vaultPnls = selectVaultPnls(state, walletAddress);
  let totalUsd = BIGNUMBER_ZERO;
  Object.values(vaultPnls).forEach(pnl => {
    totalUsd = totalUsd.plus(pnl?.totalYieldUsd)
  });
  return totalUsd;
}

