import styled from "styled-components";
import { PlatformIdentifier } from "../services/vaults/Platform";
import { findPlatform } from "../services/vaults/platforms";

export type PlatformIconProps = {
    platform: PlatformIdentifier;
    size: number;
    borderWidth?: number;
    className?: string;
}

const Image = styled.img<{ size: number, borderWidth: number }>`
    display: inline-block;
    width: ${props => props.size}px;
    border: ${props => props.borderWidth}px solid #fff;
    border-radius: 50%;
`;

const PlatformIcon: React.FC<PlatformIconProps> = ({
    className,
    platform,
    size,
    borderWidth = 3
}) => {
    const { icon } = findPlatform(platform) || {}
    return (
        <Image
            borderWidth={borderWidth}
            className={className}
            size={size}
            src={icon}
        />
    )
}

export default PlatformIcon;