import { Timebucket } from '../services/databarn/IDatabarnService';

export type SamplingPeriod =
  | '1sec'
  | '10sec'
  | '15sec'
  | '30sec'
  | '1min'
  | '5min'
  | '15min'
  | '30min'
  | '1hour'
  | '2hour'
  | '4hour'
  | '1day'
  | '3days'
  | '1week'
  | '1month'
  | '3months'
  | '1year'
  | '100year';

export const allSamplingPeriods: SamplingPeriod[] = [
  '1sec',
  '10sec',
  '15sec',
  '30sec',
  '1min',
  '5min',
  '15min',
  '30min',
  '1hour',
  '2hour',
  '4hour',
  '1day',
  '3days',
  '1week',
  '1month',
  '3months',
  '1year',
  '100year',
];

export const samplingPeriodMs: { [period in SamplingPeriod]: number } = {
  '1sec': 1000,
  '10sec': 10 * 1000,
  '15sec': 15 * 1000,
  '30sec': 30 * 1000,
  '1min': 1 * 60 * 1000,
  '5min': 5 * 60 * 1000,
  '15min': 15 * 60 * 1000,
  '30min': 30 * 60 * 1000,
  '1hour': 60 * 60 * 1000,
  '2hour': 2 * 60 * 60 * 1000,
  '4hour': 4 * 60 * 60 * 1000,
  '1day': 24 * 60 * 60 * 1000,
  '3days': 3 * 24 * 60 * 60 * 1000,
  '1week': 7 * 24 * 60 * 60 * 1000,
  '1month': 30 * 24 * 60 * 60 * 1000,
  '3months': 3 * 30 * 24 * 60 * 60 * 1000,
  '1year': 365 * 24 * 60 * 60 * 1000,
  '100year': 100 * 365 * 24 * 60 * 60 * 1000,
};

export const timeBucketToSamplingPeriod = (timeBucket: Timebucket) => {
  const bucketParamMap: {
    [key in Timebucket]: { bucketSize: SamplingPeriod; timeRange: SamplingPeriod };
  } = {
    '1h_1d': { bucketSize: '1hour', timeRange: '1day' },
    '1h_1w': { bucketSize: '1hour', timeRange: '1week' },
    '1h_1M': { bucketSize: '1hour', timeRange: '1month' },
    '1d_1M': { bucketSize: '1day', timeRange: '1month' },
    '4h_3M': { bucketSize: '4hour', timeRange: '3months' },
    '1d_1Y': { bucketSize: '1day', timeRange: '1year' },
    '1d_all': { bucketSize: '1day', timeRange: '100year' },
  };
  return bucketParamMap[timeBucket];
}

export const assertIsValidTimeBucket = (bucketSize: SamplingPeriod, timeRange: SamplingPeriod) => {
  const isValidCombination =
    (bucketSize === '1hour' && timeRange === '1day') ||
    (bucketSize === '1hour' && timeRange === '1week') ||
    (bucketSize === '1hour' && timeRange === '1month') ||
    (bucketSize === '1day' && timeRange === '1month') ||
    (bucketSize === '4hour' && timeRange === '3months') ||
    (bucketSize === '1day' && timeRange === '1year') ||
    (bucketSize === '1day' && timeRange === '100year');
  if (!isValidCombination) {
    throw new Error('Invalid bucketSize and timeRange combination');
  }
}
