import ReactSelect, { StylesConfig, components } from 'react-select';

export type SelectOption = {
    value: string;
    label: string;
    icon?: (() => React.ReactNode) | React.ReactNode;
    backgroundColor?: string;
}

export type SelectProps = {
    className?: string;
    options: SelectOption[];
    value: SelectOption;
    onChange: (options: SelectOption[]) => void;
}

const WrappedControl = (props: any) => {
    const { children, ...rest } = props;

    const renderIcon = () => {
        if (props.hasValue) {
            const [val] = props.getValue();
            if (val?.icon) {
                return typeof val.icon === 'function'
                    ? val.icon()
                    : val.icon;
            }
        }
        return null;
    }

    return (
        <components.Control {...rest}>
            {renderIcon()}
            {children}
        </components.Control>
    )
}

const Select: React.FC<SelectProps> = ({
    options,
    onChange,
    value,
}) => {
    const styles: StylesConfig = {
        control: (base, props) => {
            let backgroundColor = '#561317';
            if (props.hasValue) {
                const [val] = props.getValue() as any;
                if (val?.backgroundColor) {
                    backgroundColor = val.backgroundColor;
                }
            }

            return {
                ...base,
                cursor: 'pointer',
                padding: '.75rem 1.25rem',
                backgroundColor,
                borderRadius: '.25rem',
                border: '2px solid #73D2DE',
                textTransform: 'uppercase'
            }
        },
        placeholder: (base) => ({
            ...base,
            color: '#fff'
        }),
        indicatorSeparator: (base) => ({
            display: 'none'
        }),
        indicatorsContainer: (base) => ({
            ...base,
            display: value ? base.display : 'none'
        }),
        dropdownIndicator: () => ({
            display: 'none'
        }),
        menu: (base) => ({
            ...base,
            backgroundColor: '#73D2DE',
        }),
        option: (base, { isSelected, isFocused }) => {
            const res = {
                ...base,
                cursor: 'pointer'
            };

            if (isSelected || isFocused) {
                res.backgroundColor = isSelected ? '#8F2D56' : '#4a6fa5';
                res.color = '#fff';
            }

            return res;
        },
        singleValue: (base) => ({
            ...base,
            color: '#fff',
            fontSize: '1rem',
            letterSpacing: '.1rem'
        }),
    }


    const handleChange = (selected: SelectOption[]) => {
        onChange(Array.isArray(selected) ? selected : [selected]);
    }

    return (
        <ReactSelect
            value={[value]}
            isMulti={false}
            isSearchable={false}
            options={options}
            onChange={handleChange as any}
            styles={styles}
            components={{
                Control: WrappedControl
            }}
        />
    )
}

export default Select;