import getNetworks from './getNetworks';
import Network from './Network';

const getNetwork = (networkId: number): Network => {
  const network = getNetworks().find(network => network.id === networkId);
  if (!network) {
    throw new Error(`Unknown network ${networkId}`);
  }
  return network;
};

export default getNetwork;
