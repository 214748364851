import { format } from 'date-fns';
import { Timebucket } from '../../../../../../../services/databarn/IDatabarnService';
import { formatLargeUsd, formatTokenDisplayCondensed } from '../../../../../../../utils/format';
import { toBigNumber } from '../../../../../../../utils/bignumber';

export const TIME_BUCKET: Timebucket[] = ['1h_1d', '1h_1w', '1d_1M', '1d_all'];

export const formatDateTimeTick = (tickItem: number, timebucket: Timebucket) => {
  const date = new Date(tickItem);
  if (timebucket === '1h_1d') {
    return format(date, 'HH:mm');
  }
  return date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric' });
};

export function formatUnderlyingTick(value: number, minMax: [number, number]) {
  const [, max] = minMax;
  if (max < 0.001) {
    return formatTokenDisplayCondensed(toBigNumber(value), 18, 4)
  }

  const decimals = max > 999 ? 0 : 3;
  return value.toLocaleString('en-US', {
    maximumFractionDigits: decimals,
    minimumFractionDigits: decimals,
  });
}

export function formatUsdTick(value: number) {
  return formatLargeUsd(value);
}

export const domainOffSet = (min: number, max: number, heightPercentageUsedByChart: number) => {
  return ((max - min) * (1 - heightPercentageUsedByChart)) / (2 * heightPercentageUsedByChart);
};

export const mapRangeToTicks = (min: number, max: number) => {
  const factors = [0, 0.25, 0.5, 0.75, 1];
  return factors.map(f => min + f * (max - min));
};

export const getXInterval = (dataLenght: number, xsDown: boolean) => {
  const interval = xsDown ? 8 : 10;
  const elementsPerResult = Math.ceil(dataLenght / interval);
  const numResults = Math.ceil(dataLenght / elementsPerResult);
  return Math.ceil(dataLenght / numResults);
};
