import { useCallback, useEffect } from "react";
import useAppDispatch from "../../hooks/useAppDispatch";
import fetchPrices from "./redux/fetchPrices";

export type PricesPollingContainerProps = {
    pollingIntervalMs: number;
}


const PricesPollingContainer: React.FC<PricesPollingContainerProps> = ({
    pollingIntervalMs
}) => {
    const dispatch = useAppDispatch();
    const boundAction = useCallback(() => {
        dispatch(fetchPrices());
    }, [dispatch]);

    useEffect(() => {
        boundAction();

        const handle = setInterval(() => {
            boundAction();
        }, pollingIntervalMs);

        return () => {
            clearInterval(handle);
        }
    }, [boundAction, pollingIntervalMs]);

    return <></>
}

export default PricesPollingContainer;