import { useState } from "react";
import styled from "styled-components";
import Vault from "../../../../../../services/vaults/Vault";
import { BalancesMapItem } from "../../../../redux/vaultsSlice";
import Deposit from "./Deposit";
import Tabs, { Tab } from "./Tabs";
import Withdraw from "./Withdraw";

export type TransactContainerProps = {
    className?: string;
    vault: Vault;
    balances: BalancesMapItem;
}

// max-width: 400px;
// margin: 0 auto;
const Container = styled.div`

`;

const Content = styled.div`
    padding: 1rem;
    background-color: #73D2DE;
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
`;

const TABS: Tab[] = [
    { id: 'deposit', label: 'Deposit' },
    { id: 'withdraw', label: 'Withdraw' }
]

const TransactContainer: React.FC<TransactContainerProps> = ({
    vault,
    balances
}) => {

    const [selectedTab, setSelectedTab] = useState<string>(TABS[0].id);

    const renderContent = () => {
        if (selectedTab === TABS[0].id) {
            return (
                <Deposit vault={vault} balances={balances} />
            )
        } else if (selectedTab === TABS[1].id) {
            return (
                <Withdraw vault={vault} balances={balances} />
            )
        }
    }

    return (
        <Container>
            <Tabs
                tabs={TABS}
                selectedTabId={selectedTab}
                onTabChanged={(tab) => setSelectedTab(tab)}
            />

            <Content>
                {renderContent()}
            </Content>

        </Container>
    )
}

export default TransactContainer;