import Vault from "../../../../../../../services/vaults/Vault";
import SortDirection, { flipIfAsc } from "./direction";

const sortByApy = (
    vaults: Vault[],
    apysMap: { [vaultId: string]: number },
    sortDirection: SortDirection
): Vault[] => {
    const tmpVaults = [...vaults];
    const getValue = (id: string) => apysMap[id] || 0;
    return tmpVaults.sort(
        (v1, v2) => flipIfAsc(getValue(v2.id) - getValue(v1.id), sortDirection)
    );
}

export default sortByApy;