
import { useEffect } from 'react';
import { ToastContainer, toast, TypeOptions } from 'react-toastify';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';
import { NotificationType, notificationDismissed } from './redux/notificationsSlice';
import useAppDispatch from '../../hooks/useAppDispatch';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';

const notificationTypeToToastType = (type: NotificationType): TypeOptions => {
    if (type === NotificationType.error) {
        return 'error';
    } else if (type === NotificationType.warning) {
        return 'warning';
    } else if (type === NotificationType.success) {
        return 'success';
    } else {
        return 'default';
    }
}

const ToastTitle = styled.h1`
    font-weight: bold;
`;

const ToastMessage = styled.div`

`;

const NotificationContainer = () => {
    const { notifications } = useSelector((state: RootState) => state.notifications);
    const dispatch = useAppDispatch();

    useEffect(() => {
        notifications.forEach((notification) => {
            toast((
                <>
                    <ToastTitle>{notification.title}</ToastTitle>
                    <ToastMessage>{notification.message}</ToastMessage>
                </>
            ), {
                type: notificationTypeToToastType(notification.type),
                autoClose: 5000,
                closeOnClick: true,
                theme: 'dark'
            });
            dispatch(notificationDismissed(notification.id));
        });
    }, [notifications]);

    return (
        <ToastContainer pauseOnHover={true} />
    );
}

export default NotificationContainer;