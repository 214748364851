import { RootState } from "../../../../store";
import { selectUserDepositedVaults } from "../../../vaults/redux/selectors";
import { selectVaultById } from "../../../vaults/redux/selectors/selectVaultById";
import { selectTimelineByVaultId } from "./selectTimelineByVaultId";

export const selectHasDataToShowGraphByVaultId = (
  state: RootState,
  vaultId: string,
  walletAddress?: string
) => {
  walletAddress = walletAddress ?? state.wallet.address;
  try {
    const vault = selectVaultById(state, vaultId);
    const userDepositedVaults = selectUserDepositedVaults(state);
    const timeline = selectTimelineByVaultId(state, vaultId, walletAddress);

    return (
      userDepositedVaults.includes(vault) &&
      timeline.length > 0
    )
  } catch (e) {
    return false;
  }
}
