import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { createWeb3Modal, Web3Modal } from "../../features/wallet/createWeb3Modal";
import useWallet from "../../features/wallet/hooks/useWallet";
import Button from "../Button";

export type WalletProps = {
  align: 'right' | 'left' | 'center';
  className?: string;
}

const Container = styled.div<{ align: 'right' | 'left' | 'center' }>`
  text-align: ${props => props.align};
`;

const ConnectButton = styled.button`
  background: #dedede;
  background: linear-gradient(#ffffff, #dedede);
  border: 2px solid #ddd;
  font-size: 1.5rem;
  padding: 1rem 1.5rem;
  border-radius: 2rem;
  line-height: 1.5rem;
  color: #932C54;
  font-style: italic;
  letter-spacing: .05rem;
`;

const createShortWalletAddress = (walletAddress: string) => ([
  walletAddress.substring(0, 5),
  walletAddress.substring(walletAddress.length - 4, walletAddress.length)
].join('...'));

const Wallet: React.FC<WalletProps> = ({
  align,
  className
}) => {
  const location = useLocation();
  const [web3Modal, setWeb3Modal] = useState<Web3Modal | null>(null);
  const { signer, connectWallet, address, connected, web3ModalConnected, networkId } = useWallet();

  useEffect(() => {
    if (web3Modal && web3ModalConnected && address && networkId && !signer) {
      connectWallet(web3Modal);
    }

  }, [web3ModalConnected, address, networkId, web3Modal]);

  useEffect(() => {
    const modal = createWeb3Modal();
    setWeb3Modal(modal);
  }, []);

  const renderConnectButton = () => {
    const handleClick = () => {
      web3Modal?.open();
    }

    return (
      <ConnectButton onClick={handleClick}>
        {connected && address ? createShortWalletAddress(address) : 'Connect Wallet'}
      </ConnectButton>
    )
  }

  const renderNavigationButton = () => {
    if (!connected) {
      return null;
    }
    const link = location.pathname.includes('dashboard')
      ? <Link to='/'><Button>To vaults</Button></Link>
      : <Link to='/dashboard'><Button>To dashboard</Button></Link>;
    return (
      <div className="mt-4">{link}</div>
    )
  }

  return (
    <Container align={align} className={className}>
      {renderConnectButton()}
      {renderNavigationButton()}
    </Container >
  )
}

export default Wallet;