import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { BalancesMapItem } from "../redux/vaultsSlice";

export type UseBalanceResult = {
    balances: BalancesMapItem;
}

const useBalance = (vaultId: string): UseBalanceResult => {
    const { balancesMap } = useSelector((state: RootState) => state.vaults);
    return {
        balances: balancesMap[vaultId] || { updating: true, totalBalance: '0' },
    }
}

export default useBalance;