import { BigNumber } from "ethers";
import { formatUnits, parseUnits } from "ethers/lib/utils";
import { ChangeEvent, } from "react";
import styled from "styled-components";
import IconSet, { IconSetProps } from "./Icons";
import { Asset } from "../../../../../../services/vaults/Asset";

export type TransactInputProps = {
  className?: string;
  decimals: number;
  maxButtonValue: BigNumber;
  vaultAssets: Asset[];
  value: BigNumber;
  onChange: (value: BigNumber | null) => void;
}

const Container = styled.div`
    position: relative;
`;

const Input = styled.input<{ iconCount: number }>`
    border-radius: .25rem;
    width: 100%;
    background-color: #218380;
    padding: .5rem 3rem .5rem ${props => props.iconCount * 15 + (props.iconCount === 1 ? 35 : 25)}px;
`;

const MaxButton = styled.button`
    position: absolute;
    right: .5rem;
    top: 50%;
    transform: translateY(-50%);
    background-color: #73d2de;
    font-size: .7rem;
    font-weight: 600;
    line-height: .7rem;
    padding: .35rem .5rem .25rem;

    &:disabled {
      opacity: .5;
      cursor: not-allowed;
    }
`;

const StyledIconSet = styled(IconSet) <IconSetProps & { iconCount: number }>`
    position: absolute;
    left: ${props => props.iconCount === 1 ? 10 : 20}px;
    top: 50%;
    transform: translateY(-50%);
`;

const TransactInput: React.FC<TransactInputProps> = ({
  className,
  vaultAssets,
  maxButtonValue,
  decimals,
  value,
  onChange
}) => {
  const triggerOnChange = (value: string) => {
    onChange(value ? parseUnits(value, decimals) : BigNumber.from(0))
  }

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;
    const changeIsNumber = /^[0-9]+\.?[0-9]*$/;
    if (!value) {
      return triggerOnChange(value);
    }

    if (changeIsNumber.test(value)) {
      value = value.replace(/(^[0-9]+)(\.?[0-9]*$)/, (word, p1, p2) => {
        return Number(p1).toString() + p2;
      });
      triggerOnChange(value);
    }
  };

  const isMaxButtonValueBigNumber = BigNumber.isBigNumber(maxButtonValue);
  const isMaxButtonDisabled = !isMaxButtonValueBigNumber || maxButtonValue.eq(0);
  const handleMaxButtonClick = () => {
    if (isMaxButtonValueBigNumber) {
      triggerOnChange(formatUnits(maxButtonValue, decimals));
    }
  }

  return (
    <Container>
      <StyledIconSet iconCount={vaultAssets.length} size={25} assets={vaultAssets} borderWidth={2} />
      <Input value={formatUnits(value, decimals)} onChange={handleInputChange} iconCount={vaultAssets.length} />
      <MaxButton onClick={handleMaxButtonClick} disabled={isMaxButtonDisabled}>MAX</MaxButton>
    </Container>
  );
}

export default TransactInput;