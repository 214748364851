import { selectVaultPnl } from "./selectVaultPnl";
import { selectUserDepositedVaults } from "../../../vaults/redux/selectors";
import { RootState } from "../../../../store";

export type VaultPnlMap = Record<string, NonNullable<ReturnType<typeof selectVaultPnl>>>

export const selectVaultPnls = (
  state: RootState,
  walletAddress?: string
): VaultPnlMap => {
  const vaults = selectUserDepositedVaults(state);
  return vaults.reduce<VaultPnlMap>((vaultMap, vault) => {
    const pnl = selectVaultPnl(state, vault.id, walletAddress);
    if (pnl) {
      vaultMap[vault.id] = pnl
    }
    return vaultMap
  }, {})
}
