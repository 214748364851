import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../../store";
import { PricesFulfilled, PricesProps } from "./types";
import getDatabarnService from "../../../../services/databarn/getDatabarnService";
import { DatabarnPriceType } from "../../../../services/databarn/IDatabarnService";

export const fetchUnderlyingToUsd = createAsyncThunk<
  PricesFulfilled,
  PricesProps,
  { state: RootState }
>(
  'analytics/fetchUnderlyingToUsd',
  async ({ productKey, timebucket, walletAddress, vaultId }, { getState }) => {
    const api = getDatabarnService();
    const data = await api.getPrice(productKey, DatabarnPriceType.UnderlyingToUsd, timebucket);
    return {
      data,
      vaultId,
      timebucket,
      walletAddress: walletAddress.toLocaleLowerCase(),
      state: getState(),
    };
  }
);
