import BigNumber from "bignumber.js";
import { RootState } from "../../../../store";
import { BIGNUMBER_ZERO } from "../../../../utils/bignumber";
import { selectTimelineByVaultId } from "./selectTimelineByVaultId";

export const selectLastVaultDepositStart = (
  state: RootState,
  vaultId: string,
  walletAddress?: string
) => {
  walletAddress = walletAddress ?? state.wallet.address ?? ""
  const vaultTimeline = selectTimelineByVaultId(state, vaultId, walletAddress);
  let firstDepositDate = new Date();
  let previousBalance = BIGNUMBER_ZERO;

  for (const tx of vaultTimeline) {
    if (previousBalance.isEqualTo(BIGNUMBER_ZERO)) {
      firstDepositDate = new Date(tx.datetime);
    }
    previousBalance = new BigNumber(tx.shareBalance);
  }

  return firstDepositDate;
};
