import { MulticallWrapper } from "ethers-multicall-provider";
import { WalletState } from "./redux/walletSlice";
import Network from "../../networks/Network";
import getProvider from "./getProvider";

const getMulticallProvider = (networkOrId: number | Network, wallet?: WalletState) => {
  return MulticallWrapper.wrap(getProvider(networkOrId, wallet));
}

export type MulticallProvider = ReturnType<typeof getMulticallProvider>;

export default getMulticallProvider;