import { ethers } from "ethers";
import { AppDispatch } from "../../../store"
import {
    walletAccountChanged,
    walletConnected,
    walletConnecting,
    walletNetworkChanged
} from "./walletSlice";
import disconnectWallet from "./disconnectWallet";
import { Web3Modal } from "../createWeb3Modal";

const connectWallet = (web3Modal: Web3Modal) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(walletConnecting());
            const provider = web3Modal.getWalletProvider();
            if (!provider) {
                return;
            }

            const ethersProvider = new ethers.providers.Web3Provider(provider);

            if (provider.on) {
                provider.on('close', () => {
                    dispatch(disconnectWallet());
                });

                provider.on('disconnect', async () => {
                    dispatch(disconnectWallet());
                });

                provider.on('accountsChanged', async (accounts: string[]) => {
                    if (accounts[0]) {
                        dispatch(walletAccountChanged(accounts[0]));
                    } else {
                        dispatch(disconnectWallet());
                    }
                });

                provider.on('chainChanged', async (chainId: number) => {
                    dispatch(walletNetworkChanged(chainId));
                });
            }

            const signer = ethersProvider.getSigner();
            const address = await signer.getAddress();
            const networkId = await signer.getChainId();

            dispatch(walletConnected({ signer, address, networkId }));
        } catch (error) {
            // dispatch({ type: HOME_CONNECT_WALLET_FAILURE });
        }
    }
}

export default connectWallet;