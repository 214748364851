export enum AssetIdentifier {
  FTM,
  FANG,
  shimmer,
  LUM,
  DEEPR,
  APEin,
  sIOTA,
  RUST,
  USDT,
  ETH,
  MLUM,
  IOTA,
  USDC,
}

export type Asset = {
  id: AssetIdentifier;
  name: string;
  icon: string;
};
