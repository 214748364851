import styled from "styled-components";
import { AssetIdentifier } from "../../../../../services/vaults/Asset";
import { findAsset } from "../../../../../services/vaults/assets";

export type VaultIconProps = {
    asset: AssetIdentifier;
    size: number;
    borderWidth?: number;
    className?: string;
}

const Image = styled.img<{ size: number, borderWidth: number }>`
    display: inline-block;
    width: ${props => props.size}px;
    border: ${props => props.borderWidth}px solid #fff;
    border-radius: 50%;
`;

const VaultIcon: React.FC<VaultIconProps> = ({
    className,
    asset,
    size,
    borderWidth = 3
}) => {
    const { icon } = findAsset(asset) || {}
    return (
        <Image
            borderWidth={borderWidth}
            className={className}
            size={size}
            src={icon}
        />
    )
}

export default VaultIcon;