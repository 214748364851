import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum NotificationType {
    success,
    warning,
    error
}

export type Notification = {
    id: string;
    type: NotificationType;
    title: string;
    message: string;
}

export type NotificationState = {
    notifications: Notification[];
}

const initialState: NotificationState = {
    notifications: []
}

const randomNumber = () => Math.floor((Math.random() * 1000000) + 1);
const createNotificationId = () => `${new Date().valueOf()}-${randomNumber()}-${randomNumber()}`;

const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        notificationAdded: (state, action: PayloadAction<Omit<Notification, 'id'>>) => ({
            ...state,
            notifications: [...state.notifications, {
                ...action.payload,
                id: createNotificationId()
            }]
        }),
        notificationDismissed: (state, action: PayloadAction<string>) => ({
            ...state,
            notifications: state.notifications.filter(notification => notification.id !== action.payload)
        })
    }
});

export const {
    notificationAdded,
    notificationDismissed
} = notificationsSlice.actions

export default notificationsSlice.reducer;