import { BigNumber } from "ethers";
import styled from "styled-components";
import Vault from "../../../../../../services/vaults/Vault";
import useDeposit from "../../../../hooks/useDeposit";
import { BalancesMapItem } from "../../../../redux/vaultsSlice";
import TransactInput from "./TransactInput";
import WalletBalance from "./Balance";
import { useState } from "react";
import useWallet from "../../../../../wallet/hooks/useWallet";
import NotConnected from "./NotConnected";
import useApprove from "../../../../hooks/useApprove";
import LoadingButton from "./LoadingButton";
import Slider from "../../../../../../components/Slider";
import WrongChain from "./WrongChain";

export type DepositProps = {
    vault: Vault;
    balances: BalancesMapItem
}

const DepositInfo = styled.p`
    font-size: .8rem;
    font-weight: 400;
`;

const ActionButton = styled(LoadingButton)`

`;

const Deposit: React.FC<DepositProps> = ({
    vault,
    balances
}) => {
    const [inputValue, setInputValue] = useState<BigNumber | null>(null);
    const [sliderValue, setSliderValue] = useState<number>(0);
    const { connected, networkId } = useWallet();
    const { deposit, isDepositing } = useDeposit(vault.id);
    const { approve, isApproving } = useApprove(vault.id);

    const bnWalletBalance = BigNumber.from(balances.walletBalance || 0);
    const isInputValueBigNumber = BigNumber.isBigNumber(inputValue);
    const isRequiredToApproveMore = isInputValueBigNumber && BigNumber.from(balances.walletAllowance || '0').lt(inputValue);
    const actionButtonDisabled = (
        !isInputValueBigNumber ||
        inputValue.eq(0) ||
        inputValue.gt(bnWalletBalance)
    );
    const isConnectedToVaultsNetwork = networkId === vault.networkId;

    const actionButtonLoading = (
        isDepositing ||
        isApproving
    );

    let buttonText = 'Deposit';
    if (isRequiredToApproveMore) {
        buttonText = 'Approve';
    }

    const handleInputChange = (bnValue: BigNumber | null) => {
        setInputValue(bnValue);
        if (bnValue) {
            setSliderValue(
                Number(bnValue.mul(100).div(bnWalletBalance).toString())
            );
        }
    }

    const handleSliderChange = (value: number) => {
        setSliderValue(value);
        setInputValue(
            BigNumber.from(bnWalletBalance).mul(value).div(100)
        );
    }

    const handleActionClick = () => {
        if (!isInputValueBigNumber) {
            return;
        }

        if (isRequiredToApproveMore) {
            approve(inputValue);
        } else {
            deposit(inputValue);
        }
    }

    const renderButton = () => {
        if (!connected) {
            return <NotConnected className="mt-4" />;
        }
        if (!isConnectedToVaultsNetwork) {
            return <WrongChain className="mt-4" />
        }
        return (
            <div className="text-right">
                <ActionButton loading={actionButtonLoading} className='mt-4' onClick={handleActionClick} disabled={actionButtonDisabled}>
                    {buttonText}
                </ActionButton>
            </div>
        )
    }


    const transactInputMaxButtonValue = bnWalletBalance.gt(BigNumber.from(0)) ? bnWalletBalance : BigNumber.from(0);

    return (
        <div>
            <WalletBalance
                label="Wallet balance"
                className="mb-4"
                walletBalance={bnWalletBalance}
                tokenDecimals={vault.token.decimals}
                assets={vault.assets}
            />

            <TransactInput
                vaultAssets={vault.assets}
                onChange={handleInputChange}
                decimals={vault.token.decimals}
                maxButtonValue={transactInputMaxButtonValue}
                value={inputValue || BigNumber.from(0)}
            />

            <Slider className="mt-2" value={sliderValue} onChange={handleSliderChange} />

            {renderButton()}

            <DepositInfo className="mt-4">
                You will receive <b>{vault.earn.symbol}</b> token as a receipt for your deposited assets. This token is needed to withdraw your {vault.token.symbol}, do not trade or transfer {vault.earn.symbol} to strangers!
            </DepositInfo>
        </div >
    )
}

export default Deposit;