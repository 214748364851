export type TimelineItem = {
  datetime: string;
  productKey: string;
  displayName: string;
  chain: string;
  isEol: boolean;
  isDashboardEol: boolean;
  transactionHash: string;
  shareToUnderlyingPrice: number;
  underlyingToUsdPrice: number;
  shareBalance: number;
  shareDiff: number;
  underlyingBalance: number;
  usdBalance: number;
  underlyingDiff: number;
  usdDiff: number;
}

export type Timebucket = '1h_1d' | '1h_1w' | '1h_1M' | '4h_3M' | '1d_1M' | '1d_1Y' | '1d_all';

export enum DatabarnPriceType {
  ShareToUnderlying = 'share_to_underlying',
  UnderlyingToUsd = 'underlying_to_usd'
}

export type PriceSerieItem = {
  datetime: string;
  openPrice: string;
  lowPrice: string;
  highPrice: string;
  closePrice: string;
}

interface IDatabarnService {
  getTimeline(wallet: string): Promise<TimelineItem[]>;
  getPrice(productKey: string, type: DatabarnPriceType, timeBucket: Timebucket): Promise<PriceSerieItem[]>
}

export default IDatabarnService;