import { providers } from "ethers";
import Network from "./Network";

const PUBLIC_CHAIN_PROVIDER_MAP: { [chainId: number]: providers.Provider } = {};

const getPublicRpcProvider = (network: Network) => {
    if (!PUBLIC_CHAIN_PROVIDER_MAP[network.id]) {
        PUBLIC_CHAIN_PROVIDER_MAP[network.id] = new providers.JsonRpcProvider(network.publicRpcUrl, network.id);
    }
    return PUBLIC_CHAIN_PROVIDER_MAP[network.id];
}

export default getPublicRpcProvider;