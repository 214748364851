import { configureStore } from "@reduxjs/toolkit";

import walletReducer from "./features/wallet/redux/walletSlice";
import vaultsReducer from "./features/vaults/redux/vaultsSlice";
import pricesReducer from "./features/prices/redux/pricesSlice";
import dashboardReducer from "./features/dashboard/redux/dashboardSlice";
import notificationsReducer from "./features/notifications/redux/notificationsSlice";

const store = configureStore({
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    'wallet/walletConnected'
                ],
                ignoredPaths: [
                    'wallet.signer'
                ]
            }
        });
    },
    reducer: {
        wallet: walletReducer,
        vaults: vaultsReducer,
        dashboard: dashboardReducer,
        prices: pricesReducer,
        notifications: notificationsReducer
    }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type GetAppState = typeof store.getState;

export default store;