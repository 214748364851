import InMemoryMockedVaultsService from "./InMemoryMockedVaultsService";
import InMemoryVaultsService from "./InMemoryVaultsService";
import IVaultsService from "./IVaultsService";

let vaultsService: IVaultsService;

const getVaultsService = (): IVaultsService => {
    if (!vaultsService) {
        //  vaultsService = new InMemoryMockedVaultsService();
        vaultsService = new InMemoryVaultsService(
            `https://newapi.potluckprotocol.com/apy/breakdown`
        );
    }

    return vaultsService;
}

export default getVaultsService;