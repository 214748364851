
import BigNumber from "bignumber.js";
import { RootState } from "../../../../store";
import { BIGNUMBER_ZERO } from "../../../../utils/bignumber";
import Vault from "../../../../services/vaults/Vault";

export const selectUserDepositedVaults = (state: RootState): Vault[] => {
  const stateVaults = state.vaults.vaults;
  const vaultIdsWithBalances = Object.entries(state.vaults.balancesMap).filter(entry => new BigNumber(entry[1].stakedBalance ?? 0).gt(BIGNUMBER_ZERO)).map(entry => entry[0]);

  return vaultIdsWithBalances.map(vaultId => stateVaults.find(vault => vault.id === vaultId))
    .filter(Boolean) as Vault[];
}