import axios from "axios";
import IVaultsService from "./IVaultsService";
import Vault from "./Vault";
import vaults from "./vaults";

class InMemoryVaultsService implements IVaultsService {

    constructor(
        private apysDataUrl: string
    ) { }

    public async listVaults(): Promise<Vault[]> {
        return vaults;
    }

    public async getVaultApys(vaultIds: string[]): Promise<Map<string, number>> {
        const { data } = await axios.get(this.apysDataUrl);
        const map = new Map<string, number>();
        vaultIds.forEach(vaultId => {
            try {
                map.set(vaultId, data[vaultId].totalApy)
            } catch (e) {
                map.set(vaultId, 0);
            }
        });
        return map;
    }

}



export default InMemoryVaultsService;