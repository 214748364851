import IDatabarnService from "./IDatabarnService";
import PotluckDatabarnService from "./PotluckDatabarnService";

let databarnService: IDatabarnService;

const getDatabarnService = (): IDatabarnService => {
  if (!databarnService) {
    databarnService = new PotluckDatabarnService(
      'https://databarn.potluckprotocol.com'
    );
  }
  return databarnService;
}

export default getDatabarnService;