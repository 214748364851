import getPricesService from "../../../services/prices/getPricesService";
import Vault, { VaultOracle } from "../../../services/vaults/Vault";
import { AppDispatch, GetAppState } from "../../../store";
import mapToObject from "../../../utils/mapToObject";
import { pricesUpdated, pricesLoading, } from "./pricesSlice";

const extractTokensAndLps = (vaults: Vault[]): [string[], string[]] => {
    const tokens: { [key: string]: string } = {};
    const lps: { [key: string]: string } = {};

    vaults.forEach(vault => {
        if (vault.oracle.type === VaultOracle.Token) {
            tokens[vault.oracle.id] = vault.oracle.id;
        } else if (vault.oracle.type === VaultOracle.Lp) {
            lps[vault.oracle.id] = vault.oracle.id;
        }
    });

    return [
        Object.keys(tokens),
        Object.keys(lps)
    ];
}

const fetchPrices = () => {
    return async (dispatch: AppDispatch, getState: GetAppState) => {
        dispatch(pricesLoading());
        const { vaults, loading: vaultsLoading } = getState().vaults;
        if (!vaultsLoading) {
            const [tokenArray, lpArray] = extractTokensAndLps(vaults);
            const pricesService = getPricesService();
            const [tokens, lps] = await Promise.all([
                pricesService.getTokenPrices(tokenArray),
                pricesService.getLpPrices(lpArray)
            ]);

            dispatch(pricesUpdated({
                lps: mapToObject(lps),
                tokens: mapToObject(tokens)
            }));
        }
    }
}

export default fetchPrices;