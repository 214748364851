import { BigNumber } from "ethers";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import useAppDispatch from "../../../hooks/useAppDispatch";
import { RootState } from "../../../store";
import approve from "../redux/approve";

export type UseApproveResult = {
    approve: (amount: BigNumber) => void;
    isApproving: boolean;
}

const useApprove = (vaultId: string): UseApproveResult => {
    const dispatch = useAppDispatch()
    const { approving } = useSelector((state: RootState) => state.vaults);
    const boundDeposit = useCallback((amount: BigNumber) => {
        dispatch(approve(vaultId, amount))
    }, [dispatch, vaultId]);

    return {
        approve: boundDeposit,
        isApproving: approving.includes(vaultId)
    }
}

export default useApprove;