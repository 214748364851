import { BigNumber } from "ethers";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import useAppDispatch from "../../../hooks/useAppDispatch";
import { RootState } from "../../../store";
import withdraw from "../redux/withdraw";
import withdrawAll from "../redux/withdrawAll";

export type UseWithdrawResult = {
    withdraw: (amount: BigNumber) => void;
    withdrawAll: () => void;
    isWithdrawing: boolean;
}

const useWithdraw = (vaultId: string): UseWithdrawResult => {
    const dispatch = useAppDispatch()
    const { withdrawing } = useSelector((state: RootState) => state.vaults);
    const boundWithdraw = useCallback((amount: BigNumber) => {
        dispatch(withdraw(vaultId, amount))
    }, [dispatch, vaultId]);

    const boundWithdrawAll = useCallback(() => {
        dispatch(withdrawAll(vaultId))
    }, [dispatch, vaultId]);

    return {
        withdraw: boundWithdraw,
        withdrawAll: boundWithdrawAll,
        isWithdrawing: withdrawing.includes(vaultId)
    }
}

export default useWithdraw;