import { createAsyncThunk } from "@reduxjs/toolkit";
import { VaultTimelineEntity } from "../dashboardSlice";
import { RootState } from "../../../../store";
import getWalletTimelineService from "../../../../services/databarn/getDatabarnService";
import { TimelineItem } from "../../../../services/databarn/IDatabarnService";
import BigNumber from "bignumber.js";

export interface FetchWalletTimelineFulfilled {
  timeline: VaultTimelineEntity[];
  walletAddress: string;
  state: RootState;
}

const generateSyntheticTransactionId = (item: TimelineItem): string =>
  `${item.chain}-${item.datetime}-${new BigNumber(item.shareDiff).absoluteValue().toString(10)}`;

export const fetchWalletTimeline = createAsyncThunk<FetchWalletTimelineFulfilled, { walletAddress: string }, { state: RootState }>(
  'dashboard/fetchWAlletTimeline',
  async ({ walletAddress }, { getState }) => {
    const service = getWalletTimelineService();
    const walletTimeline = await service.getTimeline(walletAddress);

    const timeline: VaultTimelineEntity[] = walletTimeline.map((item) => ({
      ...item,
      transactionId: item.transactionHash ?? generateSyntheticTransactionId(item),
    }))

    return {
      timeline,
      walletAddress: walletAddress.toLocaleLowerCase(),
      state: getState()
    }
  })